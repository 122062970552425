import { EditorAPI } from '../EditorAPI';

export class AdminAPI {
  api: string;

  constructor(apiBaseURL: string) {
    this.api = apiBaseURL;
  }

  login(data: { token: string }) {
    const path = '/admin/login';
    const api = new EditorAPI(this.api);
    return api.post(path, data, false);
  }

  get() {
    const path = '/admin/get';
    const api = new EditorAPI(this.api);
    return api.get(path, true);
  }
}
