var r = Object.defineProperty;
var d = (o, t, a) => t in o ? r(o, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : o[t] = a;
var e = (o, t, a) => (d(o, typeof t != "symbol" ? t + "" : t, a), a);
import { Buffer as s } from "buffer";
class p {
  constructor(t) {
    e(this, "token");
    e(this, "payload");
    this.token = t, this.payload = this.decodePayload();
  }
  decodePayload() {
    const t = this.token.split(".")[1];
    try {
      return JSON.parse(s.from(t, "base64").toString("utf8"));
    } catch {
    }
    return null;
  }
  getExpiration() {
    return this.payload.exp;
  }
  getIssuedAt() {
    return this.payload.iat;
  }
  getAudience() {
    return this.payload.aud;
  }
}
export {
  p as JwtToken
};
