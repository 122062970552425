const t = {
  image: {
    key: "image",
    orientation: "landscape",
    width: 700,
    height: 480
  }
}, i = {
  type: "object",
  properties: {
    topic: {
      type: "string",
      description: 'A string that is the topic/area of this feature, must be at 1 word and at most 3 words. For example, this could be "Analytics" or "Email Marketing" or "AI Powered", something like that, emphasizing the topic of what this feature is about'
    },
    title: {
      type: "string",
      description: "A string that is the title of this feature, must be at least 5 words and at most 8 words"
    },
    subtitle: {
      type: "string",
      description: "A string that is the subtitle that describes this feature, must be at least 20 words and at most 25 words"
    },
    image: {
      type: "string",
      description: "A string that describes the image to be used in this hero section in great detail."
    },
    benefits: {
      type: "object",
      properties: {
        0: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string explains this benefit/functionality, must be at least 4 words and at most 6 words"
            },
            subtitle: {
              type: "string",
              description: "A short string explains this benefit/functionality, must be at least 4 words and at most 6 words"
            }
          },
          required: ["title", "subtitle"]
        },
        1: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string explains this benefit/functionality, must be at least 4 words and at most 6 words"
            }
          },
          required: ["title"]
        },
        2: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string explains this benefit/functionality, must be at least 4 words and at most 6 words"
            }
          },
          required: ["title"]
        }
      },
      required: ["0", "1", "2"]
    }
  },
  required: ["topic", "title", "subtitle", "image", "benefits"]
}, s = {
  type: "object",
  properties: {
    topic: {
      type: "string",
      description: 'A string that is the topic/area of this feature, must be at 1 word and at most 3 words. For example, this could be "Analytics" or "Email Marketing" or "AI Powered", something like that, emphasizing the topic of what this feature is about'
    },
    title: {
      type: "string",
      description: "A string that is the title of this feature, must be at least 5 words and at most 8 words"
    },
    subtitle: {
      type: "string",
      description: "A string that is the subtitle that describes this feature, must be at least 20 words and at most 25 words"
    },
    image: {
      type: "string",
      description: "A string that describes the image to be used in this hero section in great detail."
    },
    benefits: {
      type: "object",
      properties: {
        0: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string explains this benefit/functionality, must be at least 4 words and at most 6 words"
            }
          },
          required: ["title"]
        },
        1: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string explains this benefit/functionality, must be at least 4 words and at most 6 words"
            }
          },
          required: ["title"]
        },
        2: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string explains this benefit/functionality, must be at least 4 words and at most 6 words"
            }
          },
          required: ["title"]
        },
        3: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string explains this benefit/functionality, must be at least 4 words and at most 6 words"
            }
          },
          required: ["title"]
        }
      },
      required: ["0", "1", "2", "3"]
    }
  },
  required: ["topic", "title", "subtitle", "image", "benefits"]
}, a = {
  type: "object",
  properties: {
    topic: {
      type: "string",
      description: 'A string that is the topic/area of this feature, must be at 1 word and at most 3 words. For example, this could be "Analytics" or "Email Marketing" or "AI Powered", something like that, emphasizing the topic of what this feature is about'
    },
    title: {
      type: "string",
      description: "A string that is the title of this feature, must be at least 5 words and at most 8 words"
    },
    subtitle: {
      type: "string",
      description: "A string that is the subtitle that describes this feature, must be at least 20 words and at most 25 words"
    },
    image: {
      type: "string",
      description: "A string that describes the image to be used in this hero section in great detail."
    },
    benefits: {
      type: "object",
      properties: {
        0: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string explains this benefit/functionality, must be at least 4 words and at most 6 words"
            }
          },
          required: ["title"]
        },
        1: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string explains this benefit/functionality, must be at least 4 words and at most 6 words"
            }
          },
          required: ["title"]
        },
        2: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string explains this benefit/functionality, must be at least 4 words and at most 6 words"
            }
          },
          required: ["title"]
        }
      }
    }
  },
  required: ["topic", "title", "subtitle", "image", "benefits"]
}, e = {
  type: "object",
  properties: {
    topic: {
      type: "string",
      description: 'A string that is the topic/area of this feature, must be at 1 word and at most 3 words. For example, this could be "Analytics" or "Email Marketing" or "AI Powered", something like that, emphasizing the topic of what this feature is about'
    },
    title: {
      type: "string",
      description: "A string that is the title of this feature, must be at least 5 words and at most 8 words"
    },
    subtitle: {
      type: "string",
      description: "A string that is the subtitle that describes this feature, must be at least 20 words and at most 25 words"
    },
    image: {
      type: "string",
      description: "A string that describes the image to be used in this hero section in great detail."
    }
  },
  required: ["topic", "title", "subtitle", "image"]
}, r = {
  highlight_feature_1: {
    description: "A feature section that highlights 1 specific feature of the product or service. There are 2 columns, on the left, there is a topic, title, and subtitle that describes the feature. On the right, there is a large image that describes the feature. The image should be a mockup of the feature, detailing the feature's interface and functionalities. If the feature is a physical thing, the image should be a photo of the feature. If the feature is a service, the image should be a photo of the service. The topic is a short 2-3 words small text with the accent color, right above the title. The title is bold and large, the subtitle is a small text right below the title.",
    requiredData: e,
    images: t
  },
  highlight_feature_2: {
    description: "A feature section that highlights 1 specific feature of the product or service. There are 2 columns, on the left, there is a large image that describes the feature. On the right, there is a topic, title, and subtitle that describes the feature. The image should be a mockup of the feature, detailing the feature's interface and functionalities. If the feature is a physical thing, the image should be a photo of the feature. If the feature is a service, the image should be a photo of the service. The topic is a short 2-3 words small text with the accent color, right above the title. The title is bold and large, the subtitle is a small text right below the title.",
    requiredData: e,
    images: t
  },
  highlight_feature_3: {
    description: "A feature section that highlights 1 specific feature and showcases 3 key benefits/functionalities of the feature. There are 2 columns, on the right, there is a topic, title, and subtitle that describes the feature, as well as the list of key benefits/functionalities of the feature. On the left, there is a large image that describes the feature. The image should be a mockup of the feature, detailing the feature's interface and functionalities. If the feature is a physical thing, the image should be a photo of the feature. If the feature is a service, the image should be a photo of the service. The topic is a short 2-3 words small text with the accent color, right above the title. The title is bold and large, the subtitle is a small text right below the title. The list of key benefits/functionalities is a list of short 4-6 words text that explains the key benefits/functionalities of the feature.",
    requiredData: a,
    images: t
  },
  highlight_feature_4: {
    description: "A feature section that highlights 1 specific feature and showcases 3 key benefits/functionalities of the feature. There are 2 columns, on the left, there is a topic, title, and subtitle that describes the feature, as well as the list of key benefits/functionalities of the feature. On the right, there is a large image that describes the feature. The image should be a mockup of the feature, detailing the feature's interface and functionalities. If the feature is a physical thing, the image should be a photo of the feature. If the feature is a service, the image should be a photo of the service. The topic is a short 2-3 words small text with the accent color, right above the title. The title is bold and large, the subtitle is a small text right below the title. The list of key benefits/functionalities is a list of short 4-6 words text that explains the key benefits/functionalities of the feature.",
    requiredData: a,
    images: t
  },
  highlight_feature_5: {
    description: "A feature section that highlights 1 specific feature and showcases 4 key benefits/functionalities of the feature. There are 2 columns, on the left, there is a topic, title, and subtitle that describes the feature, as well as the list of key benefits/functionalities of the feature. On the right, there is a large image that describes the feature. The image should be a mockup of the feature, detailing the feature's interface and functionalities. If the feature is a physical thing, the image should be a photo of the feature. If the feature is a service, the image should be a photo of the service. The topic is a short 2-3 words small text with the accent color, right above the title. The title is bold and large, the subtitle is a small text right below the title. The list of key benefits/functionalities is a list of short 4-6 words text that explains the key benefits/functionalities of the feature.",
    requiredData: s,
    images: t
  },
  highlight_feature_6: {
    description: "A feature section that highlights 1 specific feature and showcases 4 key benefits/functionalities of the feature. There are 2 columns, on the right, there is a topic, title, and subtitle that describes the feature, as well as the list of key benefits/functionalities of the feature. On the left, there is a large image that describes the feature. The image should be a mockup of the feature, detailing the feature's interface and functionalities. If the feature is a physical thing, the image should be a photo of the feature. If the feature is a service, the image should be a photo of the service. The topic is a short 2-3 words small text with the accent color, right above the title. The title is bold and large, the subtitle is a small text right below the title. The list of key benefits/functionalities is a list of short 4-6 words text that explains the key benefits/functionalities of the feature.",
    requiredData: s,
    images: t
  },
  highlight_feature_7: {
    description: "A feature section that highlights 1 specific feature and showcases 3 key benefits/functionalities of the feature in a step by step manner. At the top, there is a topic, title, and subtitle that describes the feature. Below that, there are 2 columns. On the left, there is list of key benefits/functionalities of the feature in a step by step manner. On the right, there is a large image that describes the feature. The image should be a mockup of the feature, detailing the feature's interface and functionalities. If the feature is a physical thing, the image should be a photo of the feature. If the feature is a service, the image should be a photo of the service. The topic is a short 2-3 words small text with the accent color, right above the title. The title is bold and large, the subtitle is a small text right below the title. The list of key benefits/functionalities is a list of short 4-6 words text that explains the key benefits/functionalities of the feature.",
    requiredData: i,
    images: t
  },
  highlight_feature_8: {
    description: "A feature section that highlights 1 specific feature and showcases 3 key benefits/functionalities of the feature in a step by step manner. At the top, there is a topic, title, and subtitle that describes the feature. Below that, there are 2 columns. On the right, there is list of key benefits/functionalities of the feature in a step by step manner. On the left, there is a large image that describes the feature. The image should be a mockup of the feature, detailing the feature's interface and functionalities. If the feature is a physical thing, the image should be a photo of the feature. If the feature is a service, the image should be a photo of the service. The topic is a short 2-3 words small text with the accent color, right above the title. The title is bold and large, the subtitle is a small text right below the title. The list of key benefits/functionalities is a list of short 4-6 words text that explains the key benefits/functionalities of the feature.",
    requiredData: i,
    images: t
  },
  highlight_feature_9: {
    description: "A feature section that highlights 1 specific feature of the product or service. On the top, there is a topic, title, and subtitle that describes the feature. Below that, there is a large image that describes the feature. The image should be a mockup of the feature, detailing the feature's interface and functionalities. If the feature is a physical thing, the image should be a photo of the feature. If the feature is a service, the image should be a photo of the service. The topic is a short 2-3 words small text with the accent color, right above the title. The title is bold and large, the subtitle is a small text right below the title.",
    requiredData: e,
    images: {
      image: {
        key: "image",
        orientation: "landscape",
        width: 992,
        height: 680
      }
    }
  }
};
export {
  r as FeatureHighlightTemplates
};
