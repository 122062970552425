const e = {
  description: "Frequently asked questions",
  type: "object",
  properties: {
    subtitle: {
      description: "The subtitle of the footer section",
      type: "string"
    }
  },
  required: ["subtitle"]
}, t = {
  description: "Frequently asked questions",
  type: "object",
  properties: {
    subtitle: {
      description: "The subtitle of the footer section",
      type: "string"
    },
    companyName: {
      description: "The company name of the website owner",
      type: "string"
    }
  },
  required: ["subtitle", "companyName"]
}, a = {
  footer_1: {
    description: "The section is laid out as a horizontally elongated footer, neatly partitioning the brand\u2019s presence and navigation aids into a bifurcated format. On one side, there's an emblematic representation, presumably the company logo, followed by succinct text and a horizontal array of icons for social connectivity, suggesting links to social media channels. Transitioning to the other side, the space is segmented into evenly spaced columns that appear to categorize various facets of the organization, such as products, corporate information, and user resources, with each column displaying aligned textual elements that likely serve as hyperlinks directing to more detailed content. This arrangement fosters a balanced aesthetic that both encapsulates the brand's identity and provides a structured navigational framework, all set against a backdrop that suggests a clean and minimalistic approach to design.",
    requiredData: e
  },
  footer_2: {
    description: "In this section, the design establishes a harmonious balance between branding and utility. Anchored on the left is a space that likely holds a logo, paired with a brief descriptive segment below, punctuated by a trio of social media icons, inviting interaction. To the right, the content is divided into two distinct groups of navigational elements, each headlined by a term that suggests a categorical division of information or functionality. These groups are laid out horizontally, extending across the footer, with each term vertically anchoring a series of subordinate elements. This clean and modern layout, characterized by its use of white space and clear demarcation of areas, provides a streamlined user experience while encapsulating the brand's essence and facilitating easy navigation.",
    requiredData: e
  },
  footer_3: {
    description: "The design of this section is a clean and modern footer that extends across the width of the page, consisting of a unified color scheme that offers a sense of tranquility and order. On the left-hand side, there is a distinct area likely for branding, marked by a logo or icon at the top, followed by a small block of text and a horizontal line-up of icons that probably link to social platforms. Moving to the right, the layout features two aligned textual groupings, each presiding over a collection of subordinate text items that are evenly spaced. The groups are organized under headers, creating a visual hierarchy that guides the eye naturally across the footer from the brand identity to the informational links, suggesting a pathway for user engagement and resource discovery. The overall structure is minimalistic, favoring space and clean lines to deliver information efficiently.",
    requiredData: e
  },
  footer_4: {
    description: "The layout of this section unfolds in a minimalist and expansive footer format, which horizontally spans the width of the page. The left portion of the section is dedicated to what appears to be a logo or icon, anchoring the brand's presence. Adjacent to this, there is a linear arrangement of navigational elements, possibly links, that are evenly spaced and run parallel across the footer, leading to a separate block that stands in contrast to the left side, perhaps providing a brief about the company or additional information. Below these elements, a full-width horizontal line marks a boundary above which rests a copyright statement, neatly centered to unify the footer's content. The right side of the section mirrors the left in its simplicity and space, featuring a columnar setup of text-based elements that likely correspond to different aspects of the site or organization. The overall design employs a clean and airy aesthetic, emphasizing accessibility and a polished user experience.",
    requiredData: t
  }
};
export {
  a as FooterTemplates
};
