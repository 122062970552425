var $ = Object.defineProperty;
var m = (h, t, a) => t in h ? $(h, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : h[t] = a;
var f = (h, t, a) => (m(h, typeof t != "symbol" ? t + "" : t, a), a);
import { EditorAPI as u } from "../EditorAPI.js";
import { getSpacePermissionConfig as l } from "../../utils/getSpacePermissionConfig.js";
import { getPermissionAndSubscriptionConfig as g } from "../../utils/getPermissionAndSubscriptionConfig.js";
class z {
  constructor(t) {
    f(this, "api");
    this.api = t;
  }
  async get(t, a, e) {
    const i = await l(a, e), s = `/page/${t}`;
    return await new u(this.api).get(s, !0, i);
  }
  async getPublished(t) {
    const a = `/page/published/${t}`;
    return await new u(this.api).get(a, !1);
  }
  async list(t, a) {
    const e = await l(t, a), i = `/page/list/${t}`;
    return await new u(this.api).get(i, !0, e);
  }
  async updateContent(t, a, e, i, s, n, p, o) {
    const c = await g(a, n, p, o), r = {
      content: e,
      last_updated_at: i
    }, w = `/page/content/${t}?force=${s.toString()}`, d = new u(this.api);
    return d.setLogoutCallback(o), await d.put(w, r, !0, c);
  }
  async create(t, a, e, i, s, n, p) {
    const o = await g(t, n, p), c = {
      name: a,
      slug: e,
      path: i
    };
    let r = `/page/create/${t}`;
    return s && (r = `/page/create/${t}?template=true`), await new u(this.api).post(r, c, !0, o);
  }
  async createHomePage(t, a, e, i) {
    const s = await g(t, e, i), n = {
      content: a
    }, p = `/page/create/home/${t}`;
    return await new u(this.api).post(p, n, !0, s);
  }
  async updateInfo(t, a, e, i, s, n, p, o, c) {
    const r = await g(a, o, c), w = {
      name: e,
      slug: i,
      path: s,
      public: n,
      allow_duplicate: p
    }, d = `/page/info/${t}`;
    return await new u(this.api).put(d, w, !0, r);
  }
  async updateMetadata(t, a, e, i, s) {
    const n = {
      metadata: e,
      space_id: a
    };
    return this.update(t, a, n, !0, "metadata", i, s);
  }
  async updateCustomizationPartial(t, a, e, i, s) {
    const n = await g(a, i, s), p = `/page/customization_partial/${t}?force=${!0}`;
    return await new u(this.api).put(p, e, !0, n);
  }
  async delete(t, a, e, i, s, n) {
    const p = await g(a, s, n), o = {
      slug: e,
      path: i
    }, c = `/page/${t}`;
    return await new u(this.api).delete(c, o, !0, p);
  }
  async update(t, a, e, i, s, n, p) {
    const o = await g(a, n, p), c = `/page/${s}/${t}?force=${i}`;
    return await new u(this.api).put(c, e, !0, o);
  }
  async duplicate(t, a, e, i, s, n, p) {
    let o = { headers: {} };
    t || (o = await g(a, n, p));
    const c = {
      path: i
    };
    let r = `/page/duplicate/space?slug=${e}`;
    return t && (r = `/page/duplicate/create?slug=${e}`), s && (r = `${r}&duplicate_own_page=true`), await new u(this.api).post(r, c, !0, o);
  }
}
export {
  z as PageAPI
};
