import { UserAPI } from '@typedream/data';
import { AdminAPI } from './admin';
import { DocumentAPI } from './document';
import { PageAPI } from './page';
import { SpaceAPI } from './space';
import { SubscriptionAPI } from './subscription';
import { TemplateAPI } from './template';

export class API {
  api: string;

  private adminAPI: AdminAPI;

  private spaceAPI: SpaceAPI;

  private pageAPI: PageAPI;

  private subscriptionAPI: SubscriptionAPI;

  private templateAPI: TemplateAPI;

  private documentAPI: DocumentAPI;

  private userAPI: UserAPI;

  // eslint-disable-next-line no-use-before-define
  static defaultInstance: API;

  static instances = new Map<string, API>();

  static getOrCreateInstance(apiBaseURL?: string): API {
    if (!apiBaseURL) {
      if (!API.defaultInstance) {
        throw new Error('API is not initialized, call API.init(apiBaseURL);');
      }
      return API.defaultInstance;
    }

    let instance = API.instances.get(apiBaseURL);
    if (!instance) {
      instance = new API(apiBaseURL);
      API.instances.set(apiBaseURL, instance);
      return instance;
    }
    return instance;
  }

  static init(apiBaseURL: string) {
    // Q: Do we want to ignore the second initialization or throw?
    if (API.defaultInstance)
      throw new Error(
        'API is already initialized, this is likely due to running .init() twice in your program'
      );

    API.defaultInstance = new API(apiBaseURL);
    API.instances.set(apiBaseURL, this.defaultInstance);
  }

  constructor(apiBaseURL: string) {
    this.api = apiBaseURL;
    this.adminAPI = new AdminAPI(apiBaseURL);
    this.spaceAPI = new SpaceAPI(apiBaseURL);
    this.pageAPI = new PageAPI(apiBaseURL);
    this.subscriptionAPI = new SubscriptionAPI(apiBaseURL);
    this.templateAPI = new TemplateAPI(apiBaseURL);
    this.documentAPI = new DocumentAPI(apiBaseURL);
    this.userAPI = new UserAPI(apiBaseURL);
  }

  admin(): AdminAPI {
    if (!this.adminAPI || !this.adminAPI.api)
      throw new Error('API is not initialized with proper apiBaseURL');
    return this.adminAPI;
  }

  space(): SpaceAPI {
    if (!this.spaceAPI || !this.spaceAPI.api)
      throw new Error('API is not initialized with proper apiBaseURL');
    return this.spaceAPI;
  }

  page(): PageAPI {
    if (!this.pageAPI || !this.pageAPI.api)
      throw new Error('API is not initialized with proper apiBaseURL');
    return this.pageAPI;
  }

  subscription(): SubscriptionAPI {
    if (!this.subscriptionAPI || !this.subscriptionAPI.api)
      throw new Error('API is not initialized with proper apiBaseURL');
    return this.subscriptionAPI;
  }

  template(): TemplateAPI {
    if (!this.templateAPI || !this.templateAPI.api)
      throw new Error('API is not initialized with proper apiBaseURL');
    return this.templateAPI;
  }

  document(): DocumentAPI {
    if (!this.documentAPI || !this.documentAPI.api)
      throw new Error('API is not initialized with proper apiBaseURL');
    return this.documentAPI;
  }

  user(): UserAPI {
    if (!this.userAPI || !this.userAPI.api)
      throw new Error('API is not initialized with proper apiBaseURL');
    return this.userAPI;
  }
}
