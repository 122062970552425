var g = Object.defineProperty;
var f = (a, t, i) => t in a ? g(a, t, { enumerable: !0, configurable: !0, writable: !0, value: i }) : a[t] = i;
var o = (a, t, i) => (f(a, typeof t != "symbol" ? t + "" : t, i), i);
import { getSpacePermissionConfig as h } from "../../utils/getSpacePermissionConfig.js";
import { getPermissionAndSubscriptionConfig as u } from "../../utils/getPermissionAndSubscriptionConfig.js";
import { EditorAPI as p } from "../EditorAPI.js";
class P {
  constructor(t) {
    o(this, "api");
    this.api = t;
  }
  async createSite(t, i, s, e) {
    const n = await u(t, s, e), r = `/analytics/create_site/${t}`;
    return await new p(this.api).post(r, i, !0, n);
  }
  async getSharedLink(t, i, s, e) {
    const n = await h(t, e), r = `/analytics/shared_link/${t}?type=${i}&typedream_domain=${s}`;
    return await new p(this.api).get(r, !0, n);
  }
}
export {
  P as PlausibleAPI
};
