var v = Object.defineProperty;
var m = (e, a, t) => a in e ? v(e, a, { enumerable: !0, configurable: !0, writable: !0, value: t }) : e[a] = t;
var d = (e, a, t) => (m(e, typeof a != "symbol" ? a + "" : a, t), t);
import u from "axios";
import { EditorAPI as l } from "../EditorAPI.js";
import { getSpacePermissionConfig as g } from "../../utils/getSpacePermissionConfig.js";
class $ {
  constructor(a) {
    d(this, "api");
    this.api = a;
  }
  async revalidate(a, t) {
    const { spaceID: s, pageIDs: i, allPages: o, revalidateEndpoints: p } = a, n = await g(s, t), r = {
      page_ids: i,
      endpoints: p,
      all_pages: o
    }, c = `/space/revalidate/${s}`;
    return await new l(this.api).post(c, r, !0, n);
  }
  async revalidateByPath(a, t) {
    const { spaceID: s, paths: i, revalidateEndpoints: o } = a, p = await g(s, t), n = {
      paths: i,
      endpoints: o
    }, r = `/space/revalidate/page/${s}`;
    return await new l(this.api).post(r, n, !0, p);
  }
  static async revalidateStatus(a, t) {
    const s = `${a}/api/operation/${t}`;
    return await u.get(s);
  }
}
export {
  $ as RevalidateAPI
};
