import { AxiosResponse } from 'axios';
import { EditorAPI } from '../EditorAPI';

export class SubscriptionAPI {
  api: string;

  constructor(apiBaseURL: string) {
    this.api = apiBaseURL;
  }

  async updateAnalytics(spaceID: string, timezone: string) {
    const path = `/subscription/admin/analytics/${spaceID}`;
    const data = {
      analytics_activated: true,
      analytics_timezone: timezone,
    };
    const api = new EditorAPI(this.api);
    const resp = await api.put(path, data, true);
    return resp;
  }

  async list(): Promise<
    AxiosResponse<{
      subscriptions: any[];
      errors: string[];
    }>
  > {
    const path = '/subscription/admin/list';

    const api = new EditorAPI(this.api);
    const resp = await api.get(path, true);
    return resp;
  }
}
