import axios from 'axios';
import { AccessTokenInterface } from '../utils/jwt/AccessToken';
import AccessTokenHandler from '../utils/jwt/accessTokenHandler';
import logout from '../utils/jwt/logout';

export class EditorAPI {
  config: { [key: string]: any };

  requireUID = true;

  logoutCallback?: () => Promise<void>;

  api: string;

  logoutOnUnauthorized: boolean;

  constructor(apiBaseURL: string) {
    this.config = {};
    this.api = apiBaseURL;
    this.logoutOnUnauthorized = false;
  }

  setRequireUID(require: boolean) {
    this.requireUID = require;
  }

  setLogoutCallback(
    callback?: () => Promise<void>,
  ) {
    this.logoutCallback = callback;
  }

  static mergeHeaders(
    config: { headers: object, [key: string]: unknown },
    additionalConfig: { headers: object },
  ): { headers: object } {
    return {
      ...config,
      ...additionalConfig,
      headers: { ...config?.headers, ...(additionalConfig?.headers || {}) },
    };
  }

  getAccessToken(): string | undefined {
    const tok = AccessTokenHandler.getAccessToken();
    if (!tok) {
      logout(this.logoutCallback);
    }
    if (this.requireUID) {
      const payload = tok?.payload as AccessTokenInterface;
      if (!payload?.UID) {
        logout(this.logoutCallback);
      }
    }
    return tok?.token;
  }

  async get<T = any>(
    endpoint: string,
    withJWT = true,
    additionalConfig = { headers: {} },
  ) {
    let config = { headers: {} };
    if (withJWT) {
      const accessToken = this.getAccessToken();
      config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };
    }
    try {
      const resp = await axios.get<T>(
        `${this.api}${endpoint}`,
        EditorAPI.mergeHeaders(config, additionalConfig),
      );
      return resp;
    } catch (e: any) {
      if (e?.response?.status === 401 && this.logoutOnUnauthorized) {
        logout(this.logoutCallback);
      }
      throw e;
    }
  }

  async post<T= any>(
    endpoint: string,
    payload: any,
    withJWT = true,
    additionalConfig = { headers: {} },
  ) {
    let config = { headers: {} };
    if (withJWT) {
      const accessToken = this.getAccessToken();
      config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };
    }
    try {
      const resp = await axios.post<T>(
        `${this.api}${endpoint}`,
        payload,
        EditorAPI.mergeHeaders(config, additionalConfig),
      );
      return resp;
    } catch (e: any) {
      if (e?.response?.status === 401 && this.logoutOnUnauthorized) {
        logout(this.logoutCallback);
      }
      throw e;
    }
  }

  async put<T = any>(
    endpoint: string,
    payload: any,
    withJWT = true,
    additionalConfig = { headers: {} },
  ) {
    let config = { headers: {} };
    if (withJWT) {
      const accessToken = this.getAccessToken();
      config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };
    }
    try {
      const resp = await axios.put<T>(
        `${this.api}${endpoint}`,
        payload,
        EditorAPI.mergeHeaders(config, additionalConfig),
      );
      return resp;
    } catch (e: any) {
      if (e?.response?.status === 401 && this.logoutOnUnauthorized) {
        logout(this.logoutCallback);
      }
      throw e;
    }
  }

  async patch<T = any>(
    endpoint: string,
    payload: any,
    withJWT = true,
    additionalConfig = { headers: {} },
  ) {
    let config = { headers: {} };
    if (withJWT) {
      const accessToken = this.getAccessToken();
      config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };
    }
    try {
      const resp = await axios.patch<T>(
        `${this.api}${endpoint}`,
        payload,

        EditorAPI.mergeHeaders(config, additionalConfig),
      );
      return resp;
    } catch (e: any) {
      if (e?.response?.status === 401 && this.logoutOnUnauthorized) {
        logout(this.logoutCallback);
      }
      throw e;
    }
  }

  async delete<T = any>(
    endpoint: string,
    payload: any,
    withJWT = true,
    additionalConfig = { headers: {} },
  ) {
    let config: { headers: object, data?: object } = { headers: {} };
    if (withJWT) {
      const accessToken = this.getAccessToken();
      config = {
        headers: { Authorization: `Bearer ${accessToken}` },
        data: payload,
      };
    }
    try {
      const resp = await axios.delete<T>(
        `${this.api}${endpoint}`,
        EditorAPI.mergeHeaders(config, additionalConfig),
      );
      return resp;
    } catch (e: any) {
      if (e?.response?.status === 401 && this.logoutOnUnauthorized) {
        logout(this.logoutCallback);
      }
      throw e;
    }
  }
}
