const t = {
  type: "object",
  properties: {
    title: {
      type: "string",
      description: `A string that is the tagline to be used in this logo section, sharing about the customers and encouraging trust & credibility, something like "Trusted by the biggest tech companies" or "Powering the world's best design teams". Must be at least 5 words and at most 7 words`
    },
    subtitle: {
      type: "string",
      description: 'A string that is the subtitle to be used in this logo section, giving more context about their customers, something like "Join 80,000+ designers from top companies build amazing products", etc. Must be at least 10 words and at most 15 words'
    },
    buttonText: {
      type: "string",
      description: "A string that is the text to be used in the call to action button in this logo section"
    }
  },
  required: ["title", "subtitle", "buttonText"]
}, e = {
  logo_1: {
    description: "A logo section that a list of customer logos. This section is usually put right below the Hero Section. In this logo section, there is a title and subtitle sharing about the customers that they're displaying, like 'Trusted by the biggest tech companies' or 'Powering the world's best design teams', etc. Please be creative in making sure this establishes trust and credibility. The title is bold, the subtitle is smaller and lighter. The logos are arranged horizontally.",
    requiredData: t
  },
  logo_2: {
    description: "A logo section that a list of customer logos. This section is usually put right below the Hero Section. In this logo section, there is a title sharing about the customers that they're displaying, like 'Trusted by the biggest tech companies' or 'Powering the world's best design teams', etc. Please be creative in making sure this establishes trust and credibility. The title is bold. The logos consists of 2 rows, each row contains a list of logos that are arranged horizontally.",
    requiredData: t
  },
  logo_3: {
    description: "A logo section that a list of customer logos. This logo section contains 2 columns, on the left there is a title, subtitle, and a button call to action, sharing about the customers that they're displaying, like 'Trusted by the biggest tech companies' or 'Powering the world's best design teams', etc. Please be creative in making sure this establishes trust and credibility. The title is bold, the subtitle is smaller and lighter. On the right, there is a list of 6 logos.",
    requiredData: t
  },
  logo_4: {
    description: "A logo section that a list of customer logos. This section is usually put right below the Hero Section. In this logo section, there is a list of logos arranged horizontally.",
    requiredData: t
  },
  logo_5: {
    description: "A logo section that a list of customer logos. This section is usually put right below the Hero Section. In this logo section, there is a title, sharing about the customers that they're displaying, like 'Trusted by the biggest tech companies' or 'Powering the world's best design teams', etc. Please be creative in making sure this establishes trust and credibility. The title is bold. The logos are arranged horizontally right below title.",
    requiredData: t
  },
  logo_6: {
    description: "A logo section that a list of customer logos. This section is usually put right below the Hero Section. In this logo section, there is a title, sharing about the customers that they're displaying, like 'Trusted by the biggest tech companies' or 'Powering the world's best design teams', etc. Please be creative in making sure this establishes trust and credibility. The title is bold. The logos are arranged horizontally right next to the title.",
    requiredData: t
  }
};
export {
  e as LogoTemplates
};
