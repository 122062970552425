import StandardClaims from './StandardClaims';
import JwtToken from './JwtToken';

export interface AccessTokenInterface extends StandardClaims {
  UID: string;
  email: string;
  identifier: string;
  type: string;
}

export default class AccessToken extends JwtToken {
  payload!: AccessTokenInterface;

  constructor(token: string) {
    super(token);
    this.payload = this.decodePayload();
  }

  decodePayload(): AccessTokenInterface {
    const obj = super.decodePayload();
    return <AccessTokenInterface>obj;
  }

  getUserID(): string {
    return this.payload?.UID;
  }

  getEmail(): string {
    return this.payload?.identifier;
  }
}
