var f = Object.defineProperty;
var l = (u, t, s) => t in u ? f(u, t, { enumerable: !0, configurable: !0, writable: !0, value: s }) : u[t] = s;
var h = (u, t, s) => (l(u, typeof t != "symbol" ? t + "" : t, s), s);
import { getPermissionAndSubscriptionConfig as r } from "../../utils/getPermissionAndSubscriptionConfig.js";
import { EditorAPI as c } from "../EditorAPI.js";
class b {
  constructor(t) {
    h(this, "api");
    this.api = t;
  }
  async query(t, s, a, i) {
    const n = await r(t, a, i), p = `/assistant/list/${t}?limit=${s.limit}&offset=${s.offset}&order=desc`;
    return await new c(this.api).get(p, !0, n);
  }
  async get(t, s, a, i) {
    const n = await r(t, a, i), p = `/assistant/${t}/${s}`;
    return await new c(this.api).get(p, !0, n);
  }
  async create(t, s, a, i) {
    const n = await r(t, a, i), p = `/assistant/create/${t}`;
    return await new c(this.api).post(p, s, !0, n);
  }
  async update(t, s, a, i, n) {
    const p = await r(t, i, n), o = `/assistant/${t}/${s}`;
    return await new c(this.api).put(o, a, !0, p);
  }
  async createNewGPT(t, s, a, i) {
    const n = await r(t, a, i), p = "/assistant/v1/assistants";
    return await new c(this.api).post(p, s, !0, n);
  }
  async upload(t, s, a, i, n) {
    const p = await r(a, i, n), o = new FormData();
    o.append("file", t), o.append("purpose", s);
    const e = "/assistant/v1/files";
    return await new c(this.api).post(e, o, !0, p);
  }
  async publish(t, s, a, i) {
    const n = await r(t, a, i), p = `/assistant/publish/${t}/${s}`, o = new c(this.api), e = { public: !0 };
    return await o.put(p, e, !0, n);
  }
  async unpublish(t, s, a, i) {
    const n = await r(t, a, i), p = `/assistant/publish/${t}/${s}`, o = new c(this.api), e = { public: !1 };
    return await o.put(p, e, !0, n);
  }
}
export {
  b as AssistantAPI
};
