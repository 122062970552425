var u = Object.defineProperty;
var h = (n, t, i) => t in n ? u(n, t, { enumerable: !0, configurable: !0, writable: !0, value: i }) : n[t] = i;
var m = (n, t, i) => (h(n, typeof t != "symbol" ? t + "" : t, i), i);
import { getPermissionAndSubscriptionConfig as l } from "../../utils/getPermissionAndSubscriptionConfig.js";
import { EditorAPI as f } from "../EditorAPI.js";
class A {
  constructor(t) {
    m(this, "api");
    this.api = t;
  }
  async list(t, i, a, e, o, p) {
    const r = await l(i, o, p), s = [];
    t.limit ? s.push(`limit=${t.limit}`) : s.push("limit=50"), t.offset ? s.push(`offset=${t.offset}`) : s.push("offset=0");
    const c = `/payment/list/${a}/${e}${s.length > 0 ? `?${s.join("&")}` : ""}`;
    return await new f(this.api).get(c, !0, r);
  }
  async listAll(t, i, a, e, o) {
    const p = await l(t, e, o), r = `/payment/list/${i}/${a}/all`;
    return await new f(this.api).get(r, !0, p);
  }
  async count(t, i, a, e, o) {
    const p = await l(t, e, o), r = `/payment/list/${i}/${a}/count`;
    return await new f(this.api).get(r, !0, p);
  }
}
export {
  A as PaymentAPI
};
