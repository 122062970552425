const t = {
  type: "object",
  properties: {
    title: {
      type: "string",
      description: "A string that is the title of this features section, must be at least 5 words and at most 8 words"
    },
    subtitle: {
      type: "string",
      description: "A string that is the subtitle of this features section, must be at least 15 words and at most 20 words"
    },
    features: {
      type: "object",
      properties: {
        0: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the feature, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the feature, must be at least 8 words and at most 10 words"
            }
          },
          required: ["title", "subtitle"]
        },
        1: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the feature, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the feature, must be at least 8 words and at most 10 words"
            }
          },
          required: ["title", "subtitle"]
        },
        2: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the feature, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the feature, must be at least 8 words and at most 10 words"
            }
          },
          required: ["title", "subtitle"]
        },
        3: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the feature, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the feature, must be at least 8 words and at most 10 words"
            }
          },
          required: ["title", "subtitle"]
        }
      },
      required: ["0", "1", "2", "3"]
    }
  },
  required: ["title", "subtitle", "features"]
}, e = {
  type: "object",
  properties: {
    title: {
      type: "string",
      description: "A string that is the title of this features section, must be at least 5 words and at most 8 words"
    },
    subtitle: {
      type: "string",
      description: "A string that is the subtitle of this features section, must be at least 15 words and at most 20 words"
    },
    features: {
      type: "object",
      properties: {
        0: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the feature, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the feature, must be at least 8 words and at most 10 words"
            }
          },
          required: ["title", "subtitle"]
        },
        1: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the feature, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the feature, must be at least 8 words and at most 10 words"
            }
          },
          required: ["title", "subtitle"]
        }
      },
      required: ["0", "1"]
    }
  },
  required: ["title", "subtitle", "features"]
}, s = {
  feature_1: {
    description: "Feature section displaying 4 key features in a card grid. At the top there is a large title and a smaller subtitle below. Below that, there is a grid of 4 cards, with 2 cards at the top and 2 cards at the bottom. Each of these cards represents a feature, and contains an image, a title for that feature, and a subtitle explaining the feature further",
    requiredData: t
  },
  feature_2: {
    description: "A feature section with 2 columns displaying 2 features side by side in a card-like container. Each feature is contained in this card-like container and has a title, subtitle, and an image. The title is at the top, the subtitle is below the title, and the image is at the bottom of the card. The card is rounded at the top and bottom, and has a shadow to give it depth. The title explaines the feature, and the subtitle explains the feature further. The image is a visual representation of the feature. The title is at least 1 word and at most 3 words. The subtitle is at least 10 words and at most 12 words.",
    requiredData: e
  }
};
export {
  s as FeatureTemplates
};
