import { Buffer } from 'buffer';
import StandardClaims from './StandardClaims';

class JwtToken {
  token: string;

  payload: StandardClaims | null;

  constructor(token: string) {
    this.token = token;
    this.payload = this.decodePayload();
  }

  decodePayload(): StandardClaims | null {
    const payload = this.token.split('.')[1];
    try {
      const obj = JSON.parse(Buffer.from(payload, 'base64').toString('utf8'));
      return obj;
    } catch (err) {
      return null;
    }
  }

  getExpiration(): number {
    const payload = <StandardClaims>this.payload;
    return payload.exp;
  }

  getIssuedAt(): number {
    const payload = <StandardClaims>this.payload;
    return payload.iat;
  }

  getAudience(): string {
    const payload = <StandardClaims>this.payload;
    return payload.aud;
  }
}

export default JwtToken;
