const t = {
  type: "object",
  properties: {
    topic: {
      type: "string",
      description: "A string that is the topic of this benefits section, must be at least 1 words and at most 3 words"
    },
    title: {
      type: "string",
      description: "A string that is the title of this benefits section, sharing the key benefit of using this product/service, must be at least 3 words and at most 5 words"
    },
    subtitle: {
      type: "string",
      description: "A string that is the subtitle of this benefits section, explaining more about the benefits of using this product/service, must be at least 30 words and at most 35 words"
    },
    benefits: {
      type: "object",
      properties: {
        0: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the benefit, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the benefit, must be at least 10 words and at most 15 words"
            }
          },
          required: ["title", "subtitle"]
        },
        1: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the benefit, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the benefit, must be at least 10 words and at most 15 words"
            }
          },
          required: ["title", "subtitle"]
        },
        2: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the benefit, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the benefit, must be at least 10 words and at most 15 words"
            }
          },
          required: ["title", "subtitle"]
        },
        3: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the benefit, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the benefit, must be at least 10 words and at most 15 words"
            }
          },
          required: ["title", "subtitle"]
        }
      },
      required: ["0", "1", "2", "3"]
    }
  },
  required: ["title", "subtitle", "benefits"]
}, e = {
  type: "object",
  properties: {
    topic: {
      type: "string",
      description: "A string that is the topic of this benefits section, must be at least 1 words and at most 3 words"
    },
    title: {
      type: "string",
      description: "A string that is the title of this benefits section, sharing the key benefit of using this product/service, must be at least 3 words and at most 5 words"
    },
    subtitle: {
      type: "string",
      description: "A string that is the subtitle of this benefits section, explaining more about the benefits of using this product/service, must be at least 30 words and at most 35 words"
    },
    benefits: {
      type: "object",
      properties: {
        0: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the benefit, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the benefit, must be at least 10 words and at most 15 words"
            }
          },
          required: ["title", "subtitle"]
        },
        1: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the benefit, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the benefit, must be at least 10 words and at most 15 words"
            }
          },
          required: ["title", "subtitle"]
        },
        2: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the benefit, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the benefit, must be at least 10 words and at most 15 words"
            }
          },
          required: ["title", "subtitle"]
        },
        3: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the benefit, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the benefit, must be at least 10 words and at most 15 words"
            }
          },
          required: ["title", "subtitle", "topic"]
        },
        4: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the benefit, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the benefit, must be at least 10 words and at most 15 words"
            }
          },
          required: ["title", "subtitle", "topic"]
        },
        5: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the benefit, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the benefit, must be at least 10 words and at most 15 words"
            }
          },
          required: ["title", "subtitle", "topic"]
        }
      },
      required: ["0", "1", "2", "3", "4", "5"]
    }
  },
  required: ["title", "subtitle", "benefits"]
}, i = {
  benefits_1: {
    description: "Benefits section displaying 4 key benefits in cards that are arranged horizontally. At the top there is a topic, a large title and a smaller subtitle below. Below that, there is a horizontal list of 4 cards. Each of these cards represents a benefit, and contains an icon, a title for that benefit, and a subtitle explaining the benefit further",
    requiredData: t
  },
  benefits_2: {
    description: "Benefits section displaying 6 key benefits in a grid of 3x2. At the top there is a topic, a large title and a smaller subtitle below. Below that, there are 3x2 grid listing of 6 benefits. Each of the item in the grid represents a benefit, and contains an icon, a title for that benefit, and a subtitle explaining the benefit further",
    requiredData: e
  }
};
export {
  i as BenefitTemplates
};
