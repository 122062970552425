import AccessToken from './AccessToken';

const ACCESS_TOKEN_STORAGE_KEY = 'act';

class AccessTokenHandler {
  static storeTokens(token: string) {
    window?.localStorage?.setItem(ACCESS_TOKEN_STORAGE_KEY, token);
  }

  static getAccessToken() {
    if (typeof window !== 'undefined') {
      const tok = window?.localStorage?.getItem(ACCESS_TOKEN_STORAGE_KEY);
      if (!tok || tok === 'undefined' || tok === 'null') return null;
      const accessToken = new AccessToken(tok);
      if (accessToken?.getExpiration() < new Date().getTime() / 1000) return null;
      return accessToken;
    }
    return null;
  }

  // Clear all tokens
  static removeTokens() {
    try {
      window?.localStorage?.removeItem(ACCESS_TOKEN_STORAGE_KEY);
    } catch (e) {
      // do nothing
    }
  }
}

export default AccessTokenHandler;
