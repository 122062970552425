var r = Object.defineProperty;
var o = (i, t, e) => t in i ? r(i, t, { enumerable: !0, configurable: !0, writable: !0, value: e }) : i[t] = e;
var p = (i, t, e) => (o(i, typeof t != "symbol" ? t + "" : t, e), e);
import { EditorAPI as s } from "../EditorAPI.js";
class c {
  constructor(t) {
    p(this, "api");
    this.api = t;
  }
  get() {
    const t = "/user/get";
    return new s(this.api).get(t);
  }
  emailOpenLater(t) {
    const e = `/user/email_open_later?link=https%3A%2F%2Fbuild.typedream.com%2Fdashboard%2F${t}`;
    return new s(this.api).get(e);
  }
  login(t, e) {
    let a = "/user/login";
    return e && (a = `${a}?check_only=true`), new s(this.api).post(a, t, !1);
  }
  signup(t, e) {
    let a = "/user/signup";
    return e && (a = `${a}?check_only=true`), new s(this.api).post(a, t, !1);
  }
  delete(t) {
    const e = `/user/admin/${t}`;
    return new s(this.api).delete(e, null, !0);
  }
  verify(t) {
    const e = "/user/verify?create_site=false", a = new s(this.api);
    return a.logoutOnUnauthorized = !1, a.post(e, t, !1);
  }
  verifyGoogle(t) {
    const e = "/user/verify/google?create_site=false", a = new s(this.api);
    return a.logoutOnUnauthorized = !1, a.post(e, t, !1);
  }
  async update(t) {
    const e = {
      metadata: t.metadata
    }, a = "/user/update";
    return new s(this.api).put(a, e);
  }
}
export {
  c as UserAPI
};
