var p = Object.defineProperty;
var g = (u, e, t) => e in u ? p(u, e, { enumerable: !0, configurable: !0, writable: !0, value: t }) : u[e] = t;
var i = (u, e, t) => (g(u, typeof e != "symbol" ? e + "" : e, t), t);
import l from "axios";
import { AccessTokenHandler as k } from "../utils/jwt/accessTokenHandler.js";
import { logout as h } from "../utils/jwt/logout.js";
class n {
  constructor(e) {
    i(this, "config");
    i(this, "requireUID", !0);
    i(this, "logoutCallback");
    i(this, "api");
    i(this, "logoutOnUnauthorized");
    this.config = {}, this.api = e, this.logoutOnUnauthorized = !1;
  }
  setRequireUID(e) {
    this.requireUID = e;
  }
  setLogoutCallback(e) {
    this.logoutCallback = e;
  }
  static mergeHeaders(e, t) {
    return {
      ...e,
      ...t,
      headers: { ...e == null ? void 0 : e.headers, ...(t == null ? void 0 : t.headers) || {} }
    };
  }
  getAccessToken() {
    const e = k.getAccessToken();
    if (e || h(this.logoutCallback), this.requireUID) {
      const t = e == null ? void 0 : e.payload;
      t != null && t.UID || h(this.logoutCallback);
    }
    return e == null ? void 0 : e.token;
  }
  async get(e, t = !0, c = { headers: {} }) {
    var a;
    let o = { headers: {} };
    t && (o = {
      headers: { Authorization: `Bearer ${this.getAccessToken()}` }
    });
    try {
      return await l.get(`${this.api}${e}`, n.mergeHeaders(o, c));
    } catch (r) {
      throw ((a = r == null ? void 0 : r.response) == null ? void 0 : a.status) === 401 && this.logoutOnUnauthorized && h(this.logoutCallback), r;
    }
  }
  async post(e, t, c = !0, o = { headers: {} }) {
    var r;
    let a = { headers: {} };
    c && (a = {
      headers: { Authorization: `Bearer ${this.getAccessToken()}` }
    });
    try {
      return await l.post(`${this.api}${e}`, t, n.mergeHeaders(a, o));
    } catch (s) {
      throw ((r = s == null ? void 0 : s.response) == null ? void 0 : r.status) === 401 && this.logoutOnUnauthorized && h(this.logoutCallback), s;
    }
  }
  async put(e, t, c = !0, o = { headers: {} }) {
    var r;
    let a = { headers: {} };
    c && (a = {
      headers: { Authorization: `Bearer ${this.getAccessToken()}` }
    });
    try {
      return await l.put(`${this.api}${e}`, t, n.mergeHeaders(a, o));
    } catch (s) {
      throw ((r = s == null ? void 0 : s.response) == null ? void 0 : r.status) === 401 && this.logoutOnUnauthorized && h(this.logoutCallback), s;
    }
  }
  async patch(e, t, c = !0, o = { headers: {} }) {
    var r;
    let a = { headers: {} };
    c && (a = {
      headers: { Authorization: `Bearer ${this.getAccessToken()}` }
    });
    try {
      return await l.patch(`${this.api}${e}`, t, n.mergeHeaders(a, o));
    } catch (s) {
      throw ((r = s == null ? void 0 : s.response) == null ? void 0 : r.status) === 401 && this.logoutOnUnauthorized && h(this.logoutCallback), s;
    }
  }
  async delete(e, t, c = !0, o = { headers: {} }) {
    var r;
    let a = { headers: {} };
    c && (a = {
      headers: { Authorization: `Bearer ${this.getAccessToken()}` },
      data: t
    });
    try {
      return await l.delete(`${this.api}${e}`, n.mergeHeaders(a, o));
    } catch (s) {
      throw ((r = s == null ? void 0 : s.response) == null ? void 0 : r.status) === 401 && this.logoutOnUnauthorized && h(this.logoutCallback), s;
    }
  }
}
export {
  n as EditorAPI
};
