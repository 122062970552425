const e = {
  type: "object",
  properties: {
    text: {
      type: "string",
      description: "The text of the testimonial, must be at least 15 words and at most 20 words"
    },
    author: {
      type: "string",
      description: "The name of the author of the testimonial, must be at least 2 words and at most 3 words"
    },
    jobTitle: {
      type: "string",
      description: "The job title of the author of the testimonial, must be at least 1 word and at most 2 words"
    },
    companyName: {
      type: "string",
      description: "The name of the company the author works for, must be at least 1 word and at most 2 words"
    }
  },
  required: ["text", "author", "jobTitle"]
}, o = {
  type: "object",
  properties: {
    text: {
      type: "string",
      description: "The text of the testimonial, must be at least 15 words and at most 20 words"
    }
  },
  required: ["text", "author", "jobTitle"]
}, t = {
  type: "object",
  properties: {
    title: {
      type: "string",
      description: "A short phrase describing testimonial for this website/product, must be at least 5 words and at most 10 words"
    },
    subtitle: {
      type: "string",
      description: "A call to action to join the people who have given testimonials, must be at least 3 words, and at most 5 words"
    },
    testimonials: {
      type: "object",
      properties: {
        0: {
          text: {
            type: "string",
            description: "The text of the testimonial, must be at least 15 words and at most 20 words"
          },
          author: {
            type: "string",
            description: "The name of the author of the testimonial, must be at least 2 words and at most 3 words"
          },
          handle: {
            type: "string",
            description: "The job title of the author of the testimonial, must be at least 1 word and at most 2 words"
          }
        },
        1: {
          text: {
            type: "string",
            description: "The text of the testimonial, must be at least 15 words and at most 20 words"
          },
          author: {
            type: "string",
            description: "The name of the author of the testimonial, must be at least 2 words and at most 3 words"
          },
          handle: {
            type: "string",
            description: "The job title of the author of the testimonial, must be at least 1 word and at most 2 words"
          }
        },
        2: {
          text: {
            type: "string",
            description: "The text of the testimonial, must be at least 15 words and at most 20 words"
          },
          author: {
            type: "string",
            description: "The name of the author of the testimonial, must be at least 2 words and at most 3 words"
          },
          handle: {
            type: "string",
            description: "The job title of the author of the testimonial, must be at least 1 word and at most 2 words"
          }
        },
        3: {
          text: {
            type: "string",
            description: "The text of the testimonial, must be at least 15 words and at most 20 words"
          },
          author: {
            type: "string",
            description: "The name of the author of the testimonial, must be at least 2 words and at most 3 words"
          },
          handle: {
            type: "string",
            description: "The job title of the author of the testimonial, must be at least 1 word and at most 2 words"
          }
        },
        4: {
          text: {
            type: "string",
            description: "The text of the testimonial, must be at least 15 words and at most 20 words"
          },
          author: {
            type: "string",
            description: "The name of the author of the testimonial, must be at least 2 words and at most 3 words"
          },
          handle: {
            type: "string",
            description: "The job title of the author of the testimonial, must be at least 1 word and at most 2 words"
          }
        },
        5: {
          text: {
            type: "string",
            description: "The text of the testimonial, must be at least 15 words and at most 20 words"
          },
          author: {
            type: "string",
            description: "The name of the author of the testimonial, must be at least 2 words and at most 3 words"
          },
          handle: {
            type: "string",
            description: "The job title of the author of the testimonial, must be at least 1 word and at most 2 words"
          }
        }
      },
      required: ["0", "1", "2", "3", "4", "5"]
    },
    companyName: {
      type: "string",
      description: "The name of the company the author works for, must be at least 1 word and at most 2 words"
    }
  },
  required: ["title", "subtitle", "text", "author", "jobTitle"]
}, s = {
  testimonial_1: {
    description: "This section is designed as a customer testimonial slider, featuring a strong endorsement for the product offering. The layout foregrounds a large, highlighted quote where the customer shares their positive experience. Below the quote, a photo of the endorser adds a personal touch, accompanied by the name and job title, lending credibility to the testimonial. The inclusion of a five-star rating beneath the endorser's details further emphasizes the high level of satisfaction with the services provided by the product offering. The design is clean and modern, with a focus on readability and the visual appeal of the testimonial, encouraging potential customers to view the product as a trusted and effective solution for their problem.",
    requiredData: e
  },
  testimonial_2: {
    description: "This section is a minimalist testimonial block, designed to convey customer satisfaction through a brief but impactful statement. It features a centered quote that expresses a positive outcome from using the service or product. The quote is encased in quotation marks to emphasize that it is a direct statement from a client. Below the quote, the client's attribution is presented. The design uses ample white space to draw focus to the testimonial itself, creating an uncluttered look that invites readers to pause and consider the client's experience. The simplicity of the design elements ensures that the message is clear and the endorsement is the focal point of this section.",
    requiredData: o
  },
  testimonial_3: {
    description: "This section is arranged as a grid of testimonials that offers a modern and sleek appearance. The layout consists of six individual testimonial elements, each encapsulated within its own container, arranged in two rows of three. Each container seems to include a profile picture, which adds a personal and relatable touch to each testimonial. The grid is designed with symmetry in mind, with each testimonial block occupying an equal amount of space, creating a balanced and harmonious visual effect. A prominent header is placed above the grid, likely to contextualize the section, and a call-to-action is positioned at the bottom, encouraging community engagement. The overall design leverages the contrast between the background and the testimonial blocks to draw attention to the user experiences showcased within the section.",
    requiredData: t
  },
  testimonial_4: {
    description: "This section is grid-based layout of six individual testimonial blocks. The blocks are evenly distributed across the grid, creating two rows with three columns each. Each block appears to house a circular image placeholder at the top, which likely serves to humanize the testimonials with a photo or avatar. The design allows for a uniform and orderly structure, where each block is of the same size and shape, providing a cohesive visual experience. At the top center of the section, there's a large, bold title that anchors the testimonials. Above this title, there's a navigation element, probably for additional community engagement. The alignment of the blocks and the central title creates a focal point that draws the viewer's eye across the individual experiences. The overall layout is clean, modern, and user-focused, designed to showcase community feedback in a structured and aesthetically pleasing manner",
    requiredData: t
  }
};
export {
  s as TestimonialTemplates
};
