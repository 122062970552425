var n = Object.defineProperty;
var a = (o, i, r) => i in o ? n(o, i, { enumerable: !0, configurable: !0, writable: !0, value: r }) : o[i] = r;
var t = (o, i, r) => (a(o, typeof i != "symbol" ? i + "" : i, r), r);
import { CouponAPI as p } from "./coupon/api.js";
import { PlausibleAPI as P } from "./analytics/api.js";
import { AppAPI as I } from "./app/api.js";
import { HistoryAPI as A } from "./history/api.js";
import { LockAPI as h } from "./locks/api.js";
import { PageAPI as c } from "./page/api.js";
import { SpaceAPI as w } from "./space/api.js";
import { SpacePermissionAPI as l } from "./spacepermission/api.js";
import { SubscriptionAPI as m } from "./subscription/api.js";
import { TemplateAPI as u } from "./template/api.js";
import { UserAPI as f } from "./user/api.js";
import { CollectionAPI as d } from "./collection/api.js";
import { ProductsAPI as y } from "./product/api.js";
import { PaymentAPI as E } from "./payment/api.js";
import { StripeAPI as z } from "./app/stripe/api.js";
import { PaypalAPI as g } from "./app/paypal/api.js";
import { ContactAPI as k } from "./contact/api.js";
import { EmailMarketingAPI as C } from "./emailmarketing/api.js";
import { AssistantAPI as b } from "./assistant/api.js";
import { AIProjectAPI as x } from "./ai_project/api.js";
import { AIContextAPI as M } from "./ai_context/api.js";
import { DocumentAPI as j } from "./document/api.js";
import { RevalidateAPI as v } from "./revalidate/api.js";
const e = class {
  constructor(i) {
    t(this, "api");
    t(this, "analyticsAPI");
    t(this, "appAPI");
    t(this, "documentAPI");
    t(this, "historyAPI");
    t(this, "lockAPI");
    t(this, "pageAPI");
    t(this, "spaceAPI");
    t(this, "spacePermissionAPI");
    t(this, "subscriptionAPI");
    t(this, "templateAPI");
    t(this, "userAPI");
    t(this, "collectionAPI");
    t(this, "productsAPI");
    t(this, "paymentAPI");
    t(this, "stripeAPI");
    t(this, "paypalAPI");
    t(this, "revalidateAPI");
    t(this, "contactAPI");
    t(this, "couponAPI");
    t(this, "emailMarketingAPI");
    t(this, "assistantAPI");
    t(this, "aiProjectAPI");
    t(this, "aiContextAPI");
    this.api = i, this.analyticsAPI = new P(i), this.appAPI = new I(i), this.documentAPI = new j(i), this.historyAPI = new A(i), this.lockAPI = new h(i), this.pageAPI = new c(i), this.spaceAPI = new w(i), this.spacePermissionAPI = new l(i), this.subscriptionAPI = new m(i), this.templateAPI = new u(i), this.userAPI = new f(i), this.collectionAPI = new d(i), this.productsAPI = new y(i), this.paymentAPI = new E(i), this.stripeAPI = new z(i), this.paypalAPI = new g(i), this.revalidateAPI = new v(i), this.contactAPI = new k(i), this.couponAPI = new p(i), this.emailMarketingAPI = new C(i), this.assistantAPI = new b(i), this.aiProjectAPI = new x(i), this.aiContextAPI = new M(i);
  }
  static getOrCreateInstance(i) {
    if (!i) {
      if (!e.defaultInstance)
        throw new Error("API is not initialized, call API.init(apiBaseURL);");
      return e.defaultInstance;
    }
    let r = e.instances.get(i);
    return r || (r = new e(i), e.instances.set(i, r), r);
  }
  static init(i) {
    if (e.defaultInstance)
      throw new Error("API is already initialized, this is likely due to running .init() twice in your program");
    e.defaultInstance = new e(i), e.instances.set(i, this.defaultInstance);
  }
  analytics() {
    if (!this.analyticsAPI || !this.analyticsAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.analyticsAPI;
  }
  app() {
    if (!this.appAPI || !this.appAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.appAPI;
  }
  document() {
    if (!this.documentAPI || !this.documentAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.documentAPI;
  }
  history() {
    if (!this.historyAPI || !this.historyAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.historyAPI;
  }
  lock() {
    if (!this.lockAPI || !this.lockAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.lockAPI;
  }
  page() {
    if (!this.pageAPI || !this.pageAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.pageAPI;
  }
  space() {
    if (!this.spaceAPI || !this.spaceAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.spaceAPI;
  }
  spacePermission() {
    if (!this.spacePermissionAPI || !this.spacePermissionAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.spacePermissionAPI;
  }
  subscription() {
    if (!this.subscriptionAPI || !this.subscriptionAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.subscriptionAPI;
  }
  template() {
    if (!this.templateAPI || !this.templateAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.templateAPI;
  }
  user() {
    if (!this.userAPI || !this.userAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.userAPI;
  }
  collection() {
    if (!this.collectionAPI || !this.collectionAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.collectionAPI;
  }
  products() {
    if (!this.productsAPI || !this.productsAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.productsAPI;
  }
  payment() {
    if (!this.paymentAPI || !this.paymentAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.paymentAPI;
  }
  stripe() {
    if (!this.stripeAPI || !this.stripeAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.stripeAPI;
  }
  paypal() {
    if (!this.paypalAPI || !this.paypalAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.paypalAPI;
  }
  revalidate() {
    if (!this.revalidateAPI || !this.revalidateAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.revalidateAPI;
  }
  contact() {
    if (!this.contactAPI || !this.contactAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.contactAPI;
  }
  coupon() {
    if (!this.couponAPI || !this.couponAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.couponAPI;
  }
  emailMarketing() {
    if (!this.emailMarketingAPI || !this.emailMarketingAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.emailMarketingAPI;
  }
  assistant() {
    if (!this.assistantAPI || !this.assistantAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.assistantAPI;
  }
  aiProject() {
    if (!this.aiProjectAPI || !this.aiProjectAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.aiProjectAPI;
  }
  aiContext() {
    if (!this.aiContextAPI || !this.aiContextAPI.api)
      throw new Error("API is not initialized with proper apiBaseURL");
    return this.aiContextAPI;
  }
};
let s = e;
t(s, "defaultInstance"), t(s, "instances", /* @__PURE__ */ new Map());
export {
  s as API
};
