import {
  ISectionTemplates, ITemplate, TEMPLATE_TYPE,
} from '@typedream/data';
import { EditorAPI } from '../EditorAPI';
import { NewSectionTemplateDto, UpdateTemplateDto } from './dto';

export class TemplateAPI {
  api: string;

  constructor(apiBaseURL: string) {
    this.api = apiBaseURL;
  }

  async listSection() {
    const path = '/template/section';
    const api = new EditorAPI(this.api);
    const resp = await api.get<ISectionTemplates>(path, false);
    return resp;
  }

  async get(
    spaceID: string,
    templateID: string,
  ) {
    const path = `/template/admin/${spaceID}/${templateID}`;
    const api = new EditorAPI(this.api);
    return api.get(path, true);
  }

  async create<
  T extends TEMPLATE_TYPE.SECTION | TEMPLATE_TYPE.MOBILE_SECTION>(
    newTemplate: NewSectionTemplateDto<T>,
  ) {
    const path = '/template/admin';
    const api = new EditorAPI(this.api);
    const resp = await api.post<ITemplate<T>>(path, newTemplate, true);
    return resp;
  }

  async update< T extends TEMPLATE_TYPE
  >(
    templateID: string,
    updateTemplate: UpdateTemplateDto<T>,
  ) {
    const path = `/template/admin/${templateID}`;
    const api = new EditorAPI(this.api);
    const resp = await api.put<ITemplate<T>>(path, updateTemplate, true);
    return resp;
  }

  async delete<T extends TEMPLATE_TYPE>(templateID: string) {
    const path = `/template/admin/${templateID}`;
    const api = new EditorAPI(this.api);
    const resp = await api.delete<ITemplate<T>>(path, true);
    return resp;
  }

  async list(
    spaceID: string,
    category?: string,
    type?: TEMPLATE_TYPE,
  ) {
    const params = new URLSearchParams('');

    if (category) {
      params.append('category', category);
    }
    if (type) {
      params.append('type', type);
    }
    params.append('space_id', spaceID);

    const api = new EditorAPI(this.api);

    let path = '/template/admin/list';
    path = `${path}?${params.toString()}`;
    return api.get(path, true);
  }
}
