var g = Object.defineProperty;
var h = (e, t, i) => t in e ? g(e, t, { enumerable: !0, configurable: !0, writable: !0, value: i }) : e[t] = i;
var u = (e, t, i) => (h(e, typeof t != "symbol" ? t + "" : t, i), i);
import { EditorAPI as c } from "../EditorAPI.js";
import { getSpacePermissionConfig as p } from "../../utils/getSpacePermissionConfig.js";
import { getPermissionAndSubscriptionConfig as f } from "../../utils/getPermissionAndSubscriptionConfig.js";
class y {
  constructor(t) {
    u(this, "api");
    this.api = t;
  }
  async get(t, i, a) {
    const o = await p(t, a), n = `/ai_context/${t}}/${i}`;
    return await new c(this.api).get(n, !0, o);
  }
  async create(t, i, a, o, n) {
    const s = await f(t, o, n), r = `/ai_context/${t}/${i}`;
    return await new c(this.api).post(r, a, !0, s);
  }
  async update(t, i, a, o) {
    const n = await p(t, o), s = `/ai_context/${t}/${i}`;
    return await new c(this.api).patch(s, a, !0, n);
  }
  async list(t, i, a) {
    const o = await p(t, a), n = `/ai_context/list/${t}/${i}`;
    return await new c(this.api).get(n, !0, o);
  }
  async delete(t, i, a) {
    const n = await p(t, a), s = `/ai_context/${t}}/${i}`;
    return await new c(this.api).delete(s, null, !0, n);
  }
}
export {
  y as AIContextAPI
};
