import { SUBSCRIPTION_PLAN as t, SPACE_ACTION as a, SPACE_ROLE as o, Currency as e } from "../types.js";
import { HeroTemplates as r } from "./ai/hero.templates.js";
import { FeatureTemplates as n } from "./ai/feature.templates.js";
import { FeatureHighlightTemplates as u } from "./ai/featureHighlight.templates.js";
import { LogoTemplates as i } from "./ai/logo.templates.js";
import { BenefitTemplates as d } from "./ai/benefit.templates.js";
import { FAQTemplates as R } from "./ai/faq.templates.js";
import { PricingTemplates as D } from "./ai/pricing.templates.js";
import { CallToActionTemplates as s } from "./ai/callToAction.templates.js";
import { TestimonialTemplates as l } from "./ai/testimonial.templates.js";
import { FooterTemplates as A } from "./ai/footer.templates.js";
const I = "chrome_warning_popup_hide_expiry", F = "custom_domain_popup_expiry", h = "default_space", G = "space_permissions", g = "user", U = "spaces", f = "sp", b = "sb", W = "Space-Permission", k = "Subscription", H = "/app/auth/notion", Y = "id", c = "TD", E = "TD:page_content_published", Z = "TD:slug", m = "TD:page_content", w = {
  [t.LAUNCH_15_20]: 2,
  [t.GROW]: 5
}, $ = {
  METADATA: {
    fieldName: `${c}:metadata`,
    label: "Metadata",
    description: "For SEO Settings, Twitter cards, etc."
  },
  SLUG: {
    fieldName: `${c}:slug`,
    label: "Slug",
    description: "To specify the URL for each item"
  },
  PUBLISHED_CONTENT: {
    fieldName: E,
    label: "Page Content",
    description: "To store your page's published rich text content"
  },
  CONTENT: {
    fieldName: m,
    label: "Page Content Draft",
    description: "To store your page's draft rich text content"
  }
}, z = "FREE", J = {
  [a.InviteMember]: {
    [o.OWNER]: !0,
    [o.ADMIN]: !0,
    [o.WRITER]: !1,
    [o.READER]: !1
  },
  [a.RemoveMember]: {
    [o.OWNER]: !0,
    [o.ADMIN]: !0,
    [o.WRITER]: !1,
    [o.READER]: !1
  },
  [a.ViewMembers]: {
    [o.OWNER]: !0,
    [o.ADMIN]: !0,
    [o.WRITER]: !1,
    [o.READER]: !1
  },
  [a.TransferOwnership]: {
    [o.OWNER]: !0,
    [o.ADMIN]: !0,
    [o.WRITER]: !1,
    [o.READER]: !1
  },
  [a.UpdatePermissions]: {
    [o.OWNER]: !0,
    [o.ADMIN]: !0,
    [o.WRITER]: !1,
    [o.READER]: !1
  },
  [a.UpdateSettings]: {
    [o.OWNER]: !0,
    [o.ADMIN]: !0,
    [o.WRITER]: !1,
    [o.READER]: !1
  },
  [a.UpdateCustomDomain]: {
    [o.OWNER]: !0,
    [o.ADMIN]: !0,
    [o.WRITER]: !1,
    [o.READER]: !1
  },
  [a.UpdateSubdomain]: {
    [o.OWNER]: !0,
    [o.ADMIN]: !0,
    [o.WRITER]: !1,
    [o.READER]: !1
  },
  [a.ReadSettings]: {
    [o.OWNER]: !0,
    [o.ADMIN]: !0,
    [o.WRITER]: !0,
    [o.READER]: !0
  },
  [a.ReadBilling]: {
    [o.OWNER]: !0,
    [o.ADMIN]: !0,
    [o.WRITER]: !1,
    [o.READER]: !1
  },
  [a.UpdateBilling]: {
    [o.OWNER]: !0,
    [o.ADMIN]: !0,
    [o.WRITER]: !1,
    [o.READER]: !1
  },
  [a.DeleteSpace]: {
    [o.OWNER]: !0,
    [o.ADMIN]: !0,
    [o.WRITER]: !1,
    [o.READER]: !1
  },
  [a.CreatePage]: {
    [o.OWNER]: !0,
    [o.ADMIN]: !0,
    [o.WRITER]: !0,
    [o.READER]: !1
  },
  [a.EditContent]: {
    [o.OWNER]: !0,
    [o.ADMIN]: !0,
    [o.WRITER]: !0,
    [o.READER]: !1
  },
  [a.ReadDraft]: {
    [o.OWNER]: !0,
    [o.ADMIN]: !0,
    [o.WRITER]: !0,
    [o.READER]: !1
  },
  [a.ReadPublishedContent]: {
    [o.OWNER]: !0,
    [o.ADMIN]: !0,
    [o.WRITER]: !0,
    [o.READER]: !0
  },
  [a.ReadPageInfo]: {
    [o.OWNER]: !0,
    [o.ADMIN]: !0,
    [o.WRITER]: !0,
    [o.READER]: !0
  }
}, V = "Untitled", X = "untitled", x = 4, v = 10, p = "p", N = "f", Q = ["api", "_preview", p, N], j = "TEMPLATE", q = "Network Error", ee = "Current Item - ", oe = "_CURRENT_ITEM_", ae = 5e3, te = {
  [e.AED]: "\u062F.\u0625",
  [e.AFN]: "\u060B",
  [e.ALL]: "Lek",
  [e.AMD]: "\u058F",
  [e.ANG]: "\u0192",
  [e.AOA]: "Kz",
  [e.ARS]: "$",
  [e.AUD]: "$",
  [e.AWG]: "\u0192",
  [e.AZN]: "\u20BC",
  [e.BAM]: "KM",
  [e.BBD]: "$",
  [e.BDT]: "\u09F3",
  [e.BGN]: "\u043B\u0432",
  [e.BHD]: "BD",
  [e.BIF]: "FBu",
  [e.BMD]: "$",
  [e.BND]: "$",
  [e.BOB]: "$b",
  [e.BRL]: "R$",
  [e.BSD]: "$",
  [e.BWP]: "P",
  [e.BYN]: "Br",
  [e.BZD]: "BZ$",
  [e.CAD]: "$",
  [e.CDF]: "FC",
  [e.CHF]: "CHF",
  [e.CLP]: "$",
  [e.CNY]: "\xA5",
  [e.COP]: "$",
  [e.CRC]: "\u20A1",
  [e.CVE]: "Esc",
  [e.CZK]: "K\u010D",
  [e.DJF]: "Fdj",
  [e.DKK]: "kr",
  [e.DOP]: "RD$",
  [e.DZD]: "\u062F\u062C",
  [e.EGP]: "\xA3",
  [e.ETB]: "\u1265\u122D",
  [e.EUR]: "\u20AC",
  [e.FJD]: "$",
  [e.FKP]: "\xA3",
  [e.GBP]: "\xA3",
  [e.GEL]: "\u10DA",
  [e.GIP]: "\xA3",
  [e.GMD]: "D",
  [e.GNF]: "GFr",
  [e.GTQ]: "Q",
  [e.GYD]: "$",
  [e.HKD]: "$",
  [e.HNL]: "L",
  [e.HTG]: "G",
  [e.HUF]: "Ft",
  [e.IDR]: "Rp",
  [e.ILS]: "\u20AA",
  [e.INR]: "	\u20B9",
  [e.JMD]: "J$",
  [e.JOD]: "\u062F.\u0627",
  [e.JPY]: "\xA5",
  [e.KES]: "K",
  [e.KGS]: "\u043B\u0432",
  [e.KHR]: "\u17DB",
  [e.KMF]: "CF",
  [e.KRW]: "\u20A9",
  [e.KWD]: "\u062F.\u0643",
  [e.KYD]: "$",
  [e.KZT]: "\u043B\u0432",
  [e.LAK]: "\u20AD",
  [e.LBP]: "\xA3",
  [e.LKR]: "\u20A8",
  [e.LRD]: "$",
  [e.LSL]: "L",
  [e.MAD]: "\u062F.\u0625",
  [e.MDL]: "L",
  [e.MGA]: "Ar",
  [e.MKD]: "\u0434\u0435\u043D",
  [e.MMK]: "K",
  [e.MNT]: "\u20AE",
  [e.MOP]: "MOP$",
  [e.MRO]: "MRU",
  [e.MUR]: "\u20A8",
  [e.MVR]: "Rf",
  [e.MWK]: "MK",
  [e.MXN]: "$",
  [e.MYR]: "RM",
  [e.MZN]: "MT",
  [e.NAD]: "$",
  [e.NGN]: "\u20A6",
  [e.NIO]: "C$",
  [e.NOK]: "kr",
  [e.NPR]: "\u20A8",
  [e.NZD]: "$",
  [e.OMR]: "\uFDFC",
  [e.PAB]: "B/.",
  [e.PEN]: "S/.",
  [e.PGK]: "K",
  [e.PHP]: "\u20B1",
  [e.PKR]: "\u20A8",
  [e.PLN]: "z\u0142",
  [e.PYG]: "Gs",
  [e.QAR]: "\uFDFC",
  [e.RON]: "lei",
  [e.RSD]: "\u0414\u0438\u043D.",
  [e.RUB]: "\u20BD",
  [e.RWF]: "FRw",
  [e.SAR]: "\uFDFC",
  [e.SBD]: "$",
  [e.SCR]: "\u20A8",
  [e.SEK]: "kr",
  [e.SGD]: "$",
  [e.SHP]: "\xA3",
  [e.SLE]: "Le",
  [e.SOS]: "S",
  [e.SRD]: "$",
  [e.STD]: "Db",
  [e.SZL]: "L",
  [e.THB]: "\u0E3F",
  [e.TJS]: "\u0405M",
  [e.TND]: "\u062F.\u062A",
  [e.TOP]: "T$",
  [e.TRY]: "\u20BA",
  [e.TTD]: "TT$",
  [e.TZS]: "TSh",
  [e.UAH]: "\u20B4",
  [e.USD]: "$",
  [e.UYU]: "$U",
  [e.UZS]: "\u043B\u0432",
  [e.VND]: "\u20AB",
  [e.VUV]: "VT",
  [e.WST]: "WS$",
  [e.XAF]: "FCFA",
  [e.XCD]: "$",
  [e.XOF]: "CFA",
  [e.XPF]: "\u20A3",
  [e.YER]: "\uFDFC",
  [e.ZAR]: "R",
  [e.ZMW]: "ZK"
}, ce = {
  [e.AED]: {
    country: "United Arab Emirates Dirham",
    code: e.AED
  },
  [e.AFN]: {
    country: "Afghanistan Afghani",
    code: e.AFN
  },
  [e.ALL]: {
    country: "Albania Lek",
    code: e.ALL
  },
  [e.AMD]: {
    country: "Armenia Dram",
    code: e.AMD
  },
  [e.ANG]: {
    country: "Netherlands Antilles Guilder",
    code: e.ANG
  },
  [e.AOA]: {
    country: "Angola Kwanza",
    code: e.AOA
  },
  [e.ARS]: {
    country: "Argentina Peso",
    code: e.ARS
  },
  [e.AUD]: {
    country: "Australia Dollar",
    code: e.AUD
  },
  [e.AWG]: {
    country: "Aruba Guilder",
    code: e.AWG
  },
  [e.AZN]: {
    country: "Azerbaijan Manat",
    code: e.AZN
  },
  [e.BAM]: {
    country: "Bosnia and Herzegovina Convertible Mark",
    code: e.BAM
  },
  [e.BBD]: {
    country: "Barbados Dollar",
    code: e.BBD
  },
  [e.BDT]: {
    country: "Bangladesh Taka",
    code: e.BDT
  },
  [e.BGN]: {
    country: "Bulgaria Lev",
    code: e.BGN
  },
  [e.BHD]: {
    country: "Bahrain Dinar",
    code: e.BHD,
    decimal: "three"
  },
  [e.BIF]: {
    country: "Burundi Franc",
    code: e.BIF,
    decimal: "zero"
  },
  [e.BMD]: {
    country: "Bermuda Dollar",
    code: e.BMD
  },
  [e.BND]: {
    country: "Brunei Darussalam Dollar",
    code: e.BND
  },
  [e.BOB]: {
    country: "Bolivia Bol\xEDviano",
    code: e.BOB
  },
  [e.BRL]: {
    country: "Brazil Real",
    code: e.BRL
  },
  [e.BSD]: {
    country: "Bahamas Dollar",
    code: e.BSD
  },
  [e.BWP]: {
    country: "Botswana Pula",
    code: e.BWP
  },
  [e.BYN]: {
    country: "Belarus Ruble",
    code: e.BYN
  },
  [e.BZD]: {
    country: "Belize Dollar",
    code: e.BZD
  },
  [e.CAD]: {
    country: "Canada Dollar",
    code: e.CAD
  },
  [e.CDF]: {
    country: "Replublic of Congo Franc",
    code: e.CDF
  },
  [e.CHF]: {
    country: "Switzerland Franc",
    code: e.CHF
  },
  [e.CLP]: {
    country: "Chile Peso",
    code: e.CLP,
    decimal: "zero"
  },
  [e.CNY]: {
    country: "China Yuan Renminbi",
    code: e.CNY
  },
  [e.COP]: {
    country: "Colombia Peso",
    code: e.COP
  },
  [e.CRC]: {
    country: "Costa Rica Colon",
    code: e.CRC
  },
  [e.CVE]: {
    country: "Republic of Cape Verde Escudo",
    code: e.CVE
  },
  [e.CZK]: {
    country: "Czech Republic Koruna",
    code: e.CZK
  },
  [e.DJF]: {
    country: "Djibouti Franc",
    code: e.DJF,
    decimal: "zero"
  },
  [e.DKK]: {
    country: "Denmark Krone",
    code: e.DKK
  },
  [e.DOP]: {
    country: "Dominican Republic Peso",
    code: e.DOP
  },
  [e.DZD]: {
    country: "Algeria Dinar",
    code: e.DZD
  },
  [e.EGP]: {
    country: "Egypt Pound",
    code: e.EGP
  },
  [e.ETB]: {
    country: "Ethiopia Birr",
    code: e.ETB
  },
  [e.EUR]: {
    country: "Euro Member Countries",
    code: e.EUR
  },
  [e.FJD]: {
    country: "Fiji Dollar",
    code: e.FJD
  },
  [e.FKP]: {
    country: "Falkland Islands (Malvinas) Pound",
    code: e.FKP
  },
  [e.GBP]: {
    country: "United Kingdom Pound",
    code: e.GBP
  },
  [e.GEL]: {
    country: "Georgia Lari",
    code: e.GEL
  },
  [e.GIP]: {
    country: "Gibraltar Pound",
    code: e.GIP
  },
  [e.GMD]: {
    country: "Gambia Dalasi",
    code: e.GMD
  },
  [e.GNF]: {
    country: "Guinea Franc",
    code: e.GNF,
    decimal: "zero"
  },
  [e.GTQ]: {
    country: "Guatemala Quetzal",
    code: e.GTQ
  },
  [e.GYD]: {
    country: "Guyana Dollar",
    code: e.GYD
  },
  [e.HKD]: {
    country: "Hong Kong Dollar",
    code: e.HKD
  },
  [e.HNL]: {
    country: "Honduras Lempira",
    code: e.HNL
  },
  [e.HTG]: {
    country: "Haiti Gourde",
    code: e.HTG
  },
  [e.HUF]: {
    country: "Hungary Forint",
    code: e.HUF
  },
  [e.IDR]: {
    country: "Indonesia Rupiah",
    code: e.IDR
  },
  [e.ILS]: {
    country: "Israel Shekel",
    code: e.ILS
  },
  [e.INR]: {
    country: "India Rupee",
    code: e.INR
  },
  [e.JMD]: {
    country: "Jamaica Dollar",
    code: e.JMD
  },
  [e.JOD]: {
    country: "Jordan Dinar",
    code: e.JOD,
    decimal: "three"
  },
  [e.JPY]: {
    country: "Japan Yen",
    code: e.JPY,
    decimal: "zero"
  },
  [e.KES]: {
    country: "Kenya Shilling",
    code: e.KES
  },
  [e.KGS]: {
    country: "Kyrgyzstan Som",
    code: e.KGS
  },
  [e.KHR]: {
    country: "Cambodia Riel",
    code: e.KHR
  },
  [e.KMF]: {
    country: "Comoros Franc",
    code: e.KMF,
    decimal: "zero"
  },
  [e.KRW]: {
    country: "South Korea Won",
    code: e.KRW,
    decimal: "zero"
  },
  [e.KWD]: {
    country: "Kuwait Dinar",
    code: e.KWD,
    decimal: "three"
  },
  [e.KYD]: {
    country: "Cayman Islands Dollar",
    code: e.KYD
  },
  [e.KZT]: {
    country: "Kazakhstan Tenge",
    code: e.KZT
  },
  [e.LAK]: {
    country: "Laos Kip",
    code: e.LAK
  },
  [e.LBP]: {
    country: "Lebanon Pound",
    code: e.LBP
  },
  [e.LKR]: {
    country: "Sri Lanka Rupee",
    code: e.LKR
  },
  [e.LRD]: {
    country: "Liberia Dollar",
    code: e.LRD
  },
  [e.LSL]: {
    country: "Kingdom of Lesotho Loti",
    code: e.LSL
  },
  [e.MAD]: {
    country: "Morocco Dirham",
    code: e.MAD
  },
  [e.MDL]: {
    country: "Moldova Leu",
    code: e.MDL
  },
  [e.MGA]: {
    country: "Madagascar Ariary",
    code: e.MGA,
    decimal: "zero"
  },
  [e.MKD]: {
    country: "Macedonia Denar",
    code: e.MKD
  },
  [e.MMK]: {
    country: "Myanmar Kyat",
    code: e.MMK
  },
  [e.MNT]: {
    country: "Mongolia Tughrik",
    code: e.MNT
  },
  [e.MOP]: {
    country: "Macao Pataca",
    code: e.MOP
  },
  [e.MRO]: {
    country: "Mauritania Ouguiya",
    code: e.MRO
  },
  [e.MUR]: {
    country: "Mauritius Rupee",
    code: e.MUR
  },
  [e.MVR]: {
    country: "Maldives Rufiyaa",
    code: e.MVR
  },
  [e.MWK]: {
    country: "Malawi Kwacha",
    code: e.MWK
  },
  [e.MXN]: {
    country: "Mexico Peso",
    code: e.MXN
  },
  [e.MYR]: {
    country: "Malaysia Ringgit",
    code: e.MYR
  },
  [e.MZN]: {
    country: "Mozambique Metical",
    code: e.MZN
  },
  [e.NAD]: {
    country: "Namibia Dollar",
    code: e.NAD
  },
  [e.NGN]: {
    country: "Nigeria Naira",
    code: e.NGN
  },
  [e.NIO]: {
    country: "Nicaragua Cordoba",
    code: e.NIO
  },
  [e.NOK]: {
    country: "Norway Krone",
    code: e.NOK
  },
  [e.NPR]: {
    country: "Nepal Rupee",
    code: e.NPR
  },
  [e.NZD]: {
    country: "New Zealand Dollar",
    code: e.NZD
  },
  [e.OMR]: {
    country: "Oman Rial",
    code: e.OMR,
    decimal: "three"
  },
  [e.PAB]: {
    country: "Panama Balboa",
    code: e.PAB
  },
  [e.PEN]: {
    country: "Peru Sol",
    code: e.PEN
  },
  [e.PGK]: {
    country: "Papua New Guinea Kina",
    code: e.PGK
  },
  [e.PHP]: {
    country: "Philippines Peso",
    code: e.PHP
  },
  [e.PKR]: {
    country: "Pakistan Rupee",
    code: e.PKR
  },
  [e.PLN]: {
    country: "Poland Zloty",
    code: e.PLN
  },
  [e.PYG]: {
    country: "Paraguay Guarani",
    code: e.PYG,
    decimal: "zero"
  },
  [e.QAR]: {
    country: "Qatar Riyal",
    code: e.QAR
  },
  [e.RON]: {
    country: "Romania Leu",
    code: e.RON
  },
  [e.RSD]: {
    country: "Serbia Dinar",
    code: e.RSD
  },
  [e.RUB]: {
    country: "Russia Ruble",
    code: e.RUB
  },
  [e.RWF]: {
    country: "Rwanda Franc",
    code: e.RWF,
    decimal: "zero"
  },
  [e.SAR]: {
    country: "Saudi Arabia Riyal",
    code: e.SAR
  },
  [e.SBD]: {
    country: "Solomon Islands Dollar",
    code: e.SBD
  },
  [e.SCR]: {
    country: "Seychelles Rupee",
    code: e.SCR
  },
  [e.SEK]: {
    country: "Sweden Krona",
    code: e.SEK
  },
  [e.SGD]: {
    country: "Singapore Dollar",
    code: e.SGD
  },
  [e.SHP]: {
    country: "Saint Helena Pound",
    code: e.SHP
  },
  [e.SLE]: {
    country: "Sierra Leone Leone",
    code: e.SLE
  },
  [e.SOS]: {
    country: "Somalia Shilling",
    code: e.SOS
  },
  [e.SRD]: {
    country: "Suriname Dollar",
    code: e.SRD
  },
  [e.STD]: {
    country: "S\xE3o Tom\xE9 and Pr\xEDncipe Dobra",
    code: e.STD
  },
  [e.SZL]: {
    country: "Swaziland Lilangeni",
    code: e.SZL
  },
  [e.THB]: {
    country: "Thailand Baht",
    code: e.THB
  },
  [e.TJS]: {
    country: "Tajikistan Somoni",
    code: e.TJS
  },
  [e.TND]: {
    country: "Tunisia Dinar",
    code: e.TND,
    decimal: "three"
  },
  [e.TOP]: {
    country: "Tonga Pa\u2019anga",
    code: e.TOP
  },
  [e.TRY]: {
    country: "Turkey Lira",
    code: e.TRY
  },
  [e.TTD]: {
    country: "Trinidad and Tobago Dollar",
    code: e.TTD
  },
  [e.TZS]: {
    country: "Tanzania Shilling",
    code: e.TZS
  },
  [e.UAH]: {
    country: "Ukraine Hryvnia",
    code: e.UAH
  },
  [e.USD]: {
    country: "United States Dollar",
    code: e.USD
  },
  [e.UYU]: {
    country: "Uruguay Peso",
    code: e.UYU
  },
  [e.UZS]: {
    country: "Uzbekistan Som",
    code: e.UZS
  },
  [e.VND]: {
    country: "Viet Nam Dong",
    code: e.VND,
    decimal: "zero"
  },
  [e.VUV]: {
    country: "Vanuatu Vatu",
    code: e.VUV,
    decimal: "zero"
  },
  [e.WST]: {
    country: "Samoa Tala",
    code: e.WST
  },
  [e.XAF]: {
    country: "Central Africa CFA Franc",
    code: e.XAF,
    decimal: "zero"
  },
  [e.XCD]: {
    country: "East Caribbean Dollar",
    code: e.XCD
  },
  [e.XOF]: {
    country: "West Africa CFA Franc",
    code: e.XOF,
    decimal: "zero"
  },
  [e.XPF]: {
    country: "CFP Franc",
    code: e.XPF,
    decimal: "zero"
  },
  [e.YER]: {
    country: "Yemen Rial",
    code: e.YER
  },
  [e.ZAR]: {
    country: "South Africa Rand",
    code: e.ZAR
  },
  [e.ZMW]: {
    country: "Zambia Kwacha",
    code: e.ZMW
  }
}, re = {
  [e.AED]: 3672,
  [e.AFN]: 73293,
  [e.ALL]: 95900,
  [e.AMD]: 405206,
  [e.ANG]: 1792,
  [e.AOA]: 850061,
  [e.ARS]: 841174,
  [e.AUD]: 1536,
  [e.AWG]: 1790,
  [e.AZN]: 1702,
  [e.BAM]: 1807,
  [e.BBD]: 2e3,
  [e.BDT]: 109785,
  [e.BGN]: 1807,
  [e.BHD]: 376,
  [e.BIF]: 2860052,
  [e.BMD]: 1e3,
  [e.BND]: 1345,
  [e.BOB]: 6928,
  [e.BRL]: 4933,
  [e.BSD]: 1e3,
  [e.BWP]: 13764,
  [e.BYN]: 3270,
  [e.BZD]: 2024,
  [e.CAD]: 1355,
  [e.CDF]: 2769732,
  [e.CHF]: 880,
  [e.CLP]: 981490,
  [e.CNY]: 7199,
  [e.COP]: 3932784,
  [e.CRC]: 514569,
  [e.CVE]: 101891,
  [e.CZK]: 23398,
  [e.DJF]: 177825,
  [e.DKK]: 6887,
  [e.DOP]: 58817,
  [e.DZD]: 134553,
  [e.EGP]: 30945,
  [e.ETB]: 56799,
  [e.EUR]: 923,
  [e.FJD]: 2263,
  [e.FKP]: 790,
  [e.GBP]: 790,
  [e.GEL]: 2658,
  [e.GIP]: 790,
  [e.GMD]: 67894,
  [e.GNF]: 8624969,
  [e.GTQ]: 7809,
  [e.GYD]: 209052,
  [e.HKD]: 7826,
  [e.HNL]: 24727,
  [e.HTG]: 132970,
  [e.HUF]: 361563,
  [e.IDR]: 15689025,
  [e.ILS]: 3620,
  [e.INR]: 82918,
  [e.JMD]: 156055,
  [e.JOD]: 709,
  [e.JPY]: 150700,
  [e.KES]: 145890,
  [e.KGS]: 89430,
  [e.KHR]: 4079155,
  [e.KMF]: 454649,
  [e.KRW]: 1334741,
  [e.KWD]: 307,
  [e.KYD]: 821,
  [e.KZT]: 452314,
  [e.LAK]: 20931803,
  [e.LBP]: 90350905,
  [e.LKR]: 309902,
  [e.LRD]: 191150,
  [e.LSL]: 19154,
  [e.MAD]: 10085,
  [e.MDL]: 17816,
  [e.MGA]: 4535365,
  [e.MKD]: 56872,
  [e.MMK]: 2102594,
  [e.MNT]: 3401289,
  [e.MOP]: 8061,
  [e.MRO]: 10085,
  [e.MUR]: 45769,
  [e.MVR]: 15403,
  [e.MWK]: 1686193,
  [e.MXN]: 17081,
  [e.MYR]: 4762,
  [e.MZN]: 63879,
  [e.NAD]: 19160,
  [e.NGN]: 1575322,
  [e.NIO]: 36848,
  [e.NOK]: 10585,
  [e.NPR]: 132725,
  [e.NZD]: 1638,
  [e.OMR]: 384,
  [e.PAB]: 1e3,
  [e.PEN]: 3788,
  [e.PGK]: 3770,
  [e.PHP]: 56235,
  [e.PKR]: 279260,
  [e.PLN]: 3983,
  [e.PYG]: 7282055,
  [e.QAR]: 3640,
  [e.RON]: 4591,
  [e.RSD]: 108332,
  [e.RUB]: 92004,
  [e.RWF]: 1277890,
  [e.SAR]: 3750,
  [e.SBD]: 8270,
  [e.SCR]: 13509,
  [e.SEK]: 10347,
  [e.SGD]: 1345,
  [e.SHP]: 790,
  [e.SLE]: 22732,
  [e.SOS]: 571839,
  [e.SRD]: 35258,
  [e.STD]: 22685,
  [e.SZL]: 19164,
  [e.THB]: 36064,
  [e.TJS]: 10973,
  [e.TND]: 3122,
  [e.TOP]: 2355,
  [e.TRY]: 31177,
  [e.TTD]: 6792,
  [e.TZS]: 2549648,
  [e.UAH]: 38271,
  [e.USD]: 1e3,
  [e.UYU]: 39149,
  [e.UZS]: 12529560,
  [e.VND]: 24434908,
  [e.VUV]: 120114,
  [e.WST]: 2743,
  [e.XAF]: 606361,
  [e.XCD]: 2701,
  [e.XOF]: 606317,
  [e.XPF]: 110301,
  [e.YER]: 250351,
  [e.ZAR]: 19165,
  [e.ZMW]: 23177
}, ne = "39ddf9db-1d6c-4ad0-833a-3f7df485a059", ue = {
  hero: r,
  features: n,
  featureHighlight: u,
  logo: i,
  benefits: d,
  faq: R,
  pricing: D,
  callToAction: s,
  testimonial: l,
  footer: A,
  metrics: {},
  gallery: {},
  about: {},
  contact: {},
  howItWorks: {},
  teamsList: {},
  header: {},
  content: {}
}, ie = {
  "macbook-pro-cut-mockup": {
    url: {
      darkMode: "https://typedream-assets.sfo3.cdn.digitaloceanspaces.com/ai-builder-templates/hero-mockups/macbook-pro-cut-mockup-dark.png",
      lightMode: "https://typedream-assets.sfo3.cdn.digitaloceanspaces.com/ai-builder-templates/hero-mockups/macbook-pro-cut-mockup-light.png",
      wireframeMode: "via.placeholder.com/1087x639"
    },
    description: "A mockup of a software product on a MacBook Pro. The macbook pro is cut-off at the bottom.",
    type: "hero_image",
    width: 1087,
    height: 639
  },
  "macbook-pro-mockup": {
    url: {
      darkMode: "https://typedream-assets.sfo3.cdn.digitaloceanspaces.com/ai-builder-templates/hero-mockups/macbook-pro-mockup-dark.png",
      lightMode: "https://typedream-assets.sfo3.cdn.digitaloceanspaces.com/ai-builder-templates/hero-mockups/macbook-pro-mockup-light.png",
      wireframeMode: "via.placeholder.com/1216x711"
    },
    description: "A mockup of a software product on a MacBook Pro.",
    type: "hero_image",
    width: 1216,
    height: 711
  },
  "mobile-desktop-mockup": {
    url: {
      darkMode: "https://typedream-assets.sfo3.cdn.digitaloceanspaces.com/ai-builder-templates/hero-mockups/mobile-desktop-mockup-dark.png",
      lightMode: "https://typedream-assets.sfo3.cdn.digitaloceanspaces.com/ai-builder-templates/hero-mockups/mobile-desktop-mockup-light.png",
      wireframeMode: "via.placeholder.com/982x717"
    },
    description: "A mockup of a mobile app on a mobile phone.",
    type: "hero_image",
    width: 982,
    height: 717
  },
  "mobile-widget-mockup": {
    url: {
      darkMode: "https://typedream-assets.sfo3.cdn.digitaloceanspaces.com/ai-builder-templates/hero-mockups/mobile-widget-mockup-dark.png",
      lightMode: "https://typedream-assets.sfo3.cdn.digitaloceanspaces.com/ai-builder-templates/hero-mockups/mobile-widget-mockup-light.png",
      wireframeMode: "via.placeholder.com/1022x728"
    },
    description: "A mockup of a mobile app with several widgets showing metrics and data.",
    type: "hero_image",
    width: 1022,
    height: 728
  },
  "software-mockup": {
    url: {
      darkMode: "https://typedream-assets.sfo3.cdn.digitaloceanspaces.com/ai-builder-templates/hero-mockups/software-mockup-dark.png",
      lightMode: "https://typedream-assets.sfo3.cdn.digitaloceanspaces.com/ai-builder-templates/hero-mockups/software-mockup-light.png",
      wireframeMode: "via.placeholder.com/902x496"
    },
    description: "A mockup of a software product showing metrics and data.",
    type: "hero_image",
    width: 902,
    height: 496
  }
};
export {
  ie as AIImageOptions,
  I as CHROME_WARNING_POPUP_HIDE_STORAGE_KEY,
  m as COLLECTION_CONTENT_COLUMN_NAME,
  oe as COLLECTION_FILTER_CURRENT_ITEM_PREFIX,
  ee as COLLECTION_FILTER_CURRENT_ITEM_PREFIX_COPY,
  E as COLLECTION_PUBLISHED_CONTENT_COLUMN_NAME,
  Z as COLLECTION_SLUG_COLUMN_NAME,
  re as CURRENCY_LIMIT,
  ce as CURRENCY_LIST,
  te as CURRENCY_SYMBOLS,
  F as CUSTOM_DOMAIN_POPUP_STORAGE_KEY,
  ne as DEFAULT_AI_TEMPLATE_PACK_ID,
  Y as DEFAULT_COLLECTION_DATA_ID,
  V as DEFAULT_NEW_PAGE_NAME,
  X as DEFAULT_NEW_PAGE_SLUG,
  x as DEFAULT_NEW_PAGE_SLUG_RANDOM_LENGTH,
  h as DEFAULT_SPACE_STORAGE_KEY,
  j as DUPLICATED_TABLE_ID_PREFIX,
  ae as FREE_SUBSCRIBERS_LIMIT,
  $ as ITEM_PAGE_DEFAULT_FIELDS,
  c as LOGO_NAME_ABBREVIATED,
  v as MAX_SLUG_RETRY,
  q as NETWORK_ERROR,
  H as OAUTH_REDIRECT_URI,
  p as PRODUCT_PAGE_SLUG,
  Q as RESERVED_PATHS,
  J as SPACE_PERMISSIONS,
  f as SPACE_PERMISSIONS_STORAGE_KEY,
  W as SPACE_PERMISSION_HEADER_KEY,
  G as SPACE_PERMISSION_STORAGE_KEY,
  z as SPACE_PLAN_FREE_PRODUCT_ID,
  U as SPACE_STORAGE_KEY,
  k as SUBSCRIPTION_HEADER_KEY,
  w as SUBSCRIPTION_PLAN_MAX_COLLABORATORS,
  b as SUBSCRIPTION_STORAGE_KEY,
  ue as TemplateDatabase,
  g as USER_STORAGE_KEY
};
