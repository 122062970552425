var d = Object.defineProperty;
var r = (t, o, e) => o in t ? d(t, o, { enumerable: !0, configurable: !0, writable: !0, value: e }) : t[o] = e;
var a = (t, o, e) => (r(t, typeof o != "symbol" ? o + "" : o, e), e);
import { JwtToken as s } from "./JwtToken.js";
class l extends s {
  constructor(e) {
    super(e);
    a(this, "payload");
    this.payload = this.decodePayload();
  }
  decodePayload() {
    return super.decodePayload();
  }
  getUserID() {
    var e;
    return (e = this.payload) == null ? void 0 : e.UID;
  }
  getEmail() {
    var e;
    return (e = this.payload) == null ? void 0 : e.identifier;
  }
}
export {
  l as AccessToken
};
