const t = {
  type: "object",
  properties: {
    title: {
      type: "string",
      description: "A string that is the title of this pricing section, must be at least 3 words and at most 5 words"
    },
    subtitle: {
      type: "string",
      description: "A short description of what is offered in the product, must be at least 15 words and at most 20 words"
    },
    priceCurrency: {
      type: "number",
      description: "The currency symbol used for the pricing tiers. for example, $ for USD, \xA3 for GBP, \u20AC for EUR, etc. If you don't know what currency symbol to use, use $"
    },
    pricing_tiers: {
      type: "object",
      properties: {
        0: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the lowest pricing ter, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of this lowest pricing tier, must be at least 8 words and at most 10 words"
            },
            priceAmount: {
              type: "number",
              description: "The amount of money the lowest pricing tier costs, it must be a number, if it's free, use 0."
            },
            buttonText: {
              type: "string",
              description: "The text that appears on the call to action button for cheapest pricing tier, must be at least 1 word and at most 3 words"
            }
          },
          required: ["title", "subtitle", "priceAmount", "buttonText"]
        },
        1: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the middle pricing ter, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the middle pricing tier, must be at least 8 words and at most 10 words"
            },
            priceAmount: {
              type: "number",
              description: "The amount of money middle pricing tier costs, it must be a number and it cannot be free."
            },
            buttonText: {
              type: "string",
              description: "The text that appears on the call to action button for this pricing tier, must be at least 1 word and at most 3 words"
            }
          },
          required: ["title", "subtitle", "priceAmount", "buttonText"]
        },
        2: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the most expensive pricing ter, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the most expensive pricing tier, must be at least 8 words and at most 10 words"
            },
            priceAmount: {
              type: "number",
              description: "The amount of money the most expensive pricing tier costs, it must be a number and it cannot be free."
            },
            buttonText: {
              type: "string",
              description: "The text that appears on the call to action button for most expensive pricing tier, must be at least 1 word and at most 3 words"
            }
          },
          required: ["title", "subtitle", "priceAmount", "buttonText"]
        }
      },
      required: ["0", "1", "2"]
    },
    features: {
      type: "object",
      properties: {
        0: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A basic feature that exist in all pricing tiers"
            }
          }
        },
        1: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A basic feature that exist in all pricing tiers"
            }
          }
        },
        2: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A basic feature that exist in all pricing tiers"
            }
          }
        },
        3: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A basic feature that exist in all pricing tiers"
            }
          }
        },
        4: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A standard feature that exist in mid pricing tier and expensive pricing tier"
            }
          }
        },
        5: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A standard feature that exist in mid pricing tier and expensive pricing tier"
            }
          }
        },
        6: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "An advanced feature that exist in the most expensive pricing tier"
            }
          }
        },
        7: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "An advanced feature that exist in the most expensive pricing tier"
            }
          }
        }
      },
      required: ["0", "1", "2", "3", "4", "5", "6", "7"]
    }
  },
  required: ["topic", "title", "subtitle", "pricing_tiers"]
}, e = {
  type: "object",
  properties: {
    title: {
      type: "string",
      description: "A string that is the title of this pricing section, must be at least 3 words and at most 5 words"
    },
    subtitle: {
      type: "string",
      description: "A short description of what is offered in the product, must be at least 15 words and at most 20 words"
    },
    priceCurrency: {
      type: "number",
      description: "The currency symbol used for the pricing tiers. for example, $ for USD, \xA3 for GBP, \u20AC for EUR, etc. If you don't know what currency symbol to use, use $"
    },
    pricing_tiers: {
      type: "object",
      properties: {
        1: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the middle pricing ter, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the middle pricing tier, must be at least 8 words and at most 10 words"
            },
            priceAmount: {
              type: "number",
              description: "The amount of money middle pricing tier costs per user, the cost must makes sense for a single person organization up to 50 people in an organization. it must be a number and it cannot be free."
            },
            buttonText: {
              type: "string",
              description: "The text that appears on the call to action button for this pricing tier, must be at least 1 word and at most 3 words"
            }
          },
          required: ["title", "subtitle", "priceAmount", "buttonText"]
        },
        2: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the most expensive pricing ter, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the most expensive pricing tier, must be at least 8 words and at most 10 words"
            },
            priceAmount: {
              type: "number",
              description: "The amount of money the expensive pricing tier costs per user, the cost must makes sense for 50 or more people in an organization. it must be a number and it cannot be free."
            },
            buttonText: {
              type: "string",
              description: "The text that appears on the call to action button for most expensive pricing tier, must be at least 1 word and at most 3 words"
            }
          },
          required: ["title", "subtitle", "priceAmount", "buttonText"]
        }
      },
      required: ["1", "2"]
    },
    features: {
      type: "object",
      properties: {
        0: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A basic feature that exist in all pricing tiers"
            }
          }
        },
        1: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A basic feature that exist in all pricing tiers"
            }
          }
        },
        2: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A basic feature that exist in all pricing tiers"
            }
          }
        },
        3: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A basic feature that exist in all pricing tiers"
            }
          }
        },
        4: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A standard feature that exist in mid pricing tier and expensive pricing tier"
            }
          }
        },
        5: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A standard feature that exist in mid pricing tier and expensive pricing tier"
            }
          }
        },
        6: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "An advanced feature that exist in the most expensive pricing tier"
            }
          }
        },
        7: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "An advanced feature that exist in the most expensive pricing tier"
            }
          }
        }
      },
      required: ["0", "1", "2", "3", "4", "5", "6", "7"]
    }
  },
  required: ["topic", "title", "subtitle", "pricing_tiers"]
}, i = {
  pricing_1: {
    description: "This section is structured into a three-column layout, designed to visually differentiate between various service levels. The leftmost column is distinctly marked to indicate the no-cost option, while the central and rightmost columns are set at progressively higher price points, signifying standard and premium offerings respectively. Each column is topped with a large, bold price tag that catches the eye, and beneath this headline pricing, a series of features are neatly arranged, though the specific details of these are not mentioned here. The pricing headlines are followed by a call-to-action button anchored at the bottom of each column, inviting interaction from the prospective customer. A concise title and a supporting subtitle span across the top of the three columns, creating a cohesive header for the section. The overall design leans towards a minimalistic and clean aesthetic, utilizing space and contrast to guide the viewer's eye from the most accessible plan on the left to the most comprehensive on the right, symbolizing an ascension in value and investment",
    requiredData: t
  },
  pricing_2: {
    description: "This section is crafted with a clean, modern design that presents three distinct options in a horizontal layout, emphasizing clarity and choice. At the apex of the section, a bold header announces the purpose of the segment, flanked by an informative tagline that suggests both inclusivity for teams and an invitation to upgrade. Each of the three columns is enclosed within a card with a subtle shadow. Visually, the columns are balanced in width and spacing, with a color-coded scheme that alternates between the features for visual ease. The pricing is prominently displayed at the top of each column, serving as a clear demarcation of the service tiers offered. Below the pricing, a series of indicators align in vertical succession, providing a visual hierarchy of the services available within each tier. Each column concludes with a contrasting call-to-action button, inviting engagement from the user. The overall aesthetic is minimalist yet functional, with a focus on user navigation and information accessibility.",
    requiredData: e
  }
};
export {
  i as PricingTemplates
};
