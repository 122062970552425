var b = Object.defineProperty;
var d = (e, t, i) => t in e ? b(e, t, { enumerable: !0, configurable: !0, writable: !0, value: i }) : e[t] = i;
var h = (e, t, i) => (d(e, typeof t != "symbol" ? t + "" : t, i), i);
import { getSpacePermissionConfig as u } from "../../utils/getSpacePermissionConfig.js";
import { getPermissionAndSubscriptionConfig as f } from "../../utils/getPermissionAndSubscriptionConfig.js";
import { EditorAPI as p } from "../EditorAPI.js";
class P {
  constructor(t) {
    h(this, "api");
    this.api = t;
  }
  async get(t, i) {
    const o = await u(t, i), a = `/subscription/${t}`;
    try {
      const c = await new p(this.api).get(a, !0, o);
      return c == null ? void 0 : c.data;
    } catch (s) {
      throw s.response.data;
    }
  }
  async createSubscription(t, i, o) {
    const a = await u(t, o), s = `/subscription/create_subscription/${t}`, n = await new p(this.api).post(s, i, !0, a);
    return n == null ? void 0 : n.data;
  }
  async updateSubscription(t, i, o) {
    const a = await u(t, o), s = `/subscription/update_subscription/${t}`, n = await new p(this.api).put(s, i, !0, a);
    return n == null ? void 0 : n.data;
  }
  async cancelSubscription(t, i, o) {
    const a = await u(t, o), s = `/subscription/cancel_subscription/${t}`, n = await new p(this.api).put(s, i, !0, a);
    return n == null ? void 0 : n.data;
  }
  async checkPromoCode(t) {
    const i = `/subscription/promo_code/${t}`, a = await new p(this.api).get(i, !1);
    return a == null ? void 0 : a.data;
  }
  async getStripePortal(t, i, o) {
    const a = await u(t, o), s = `/subscription/manage/${t}`, c = {
      return_url: i
    }, r = await new p(this.api).post(s, c, !0, a);
    return r == null ? void 0 : r.data;
  }
  async getProrateInfo(t, i, o, a, s, c) {
    const n = await f(t, a, s);
    let r = `/subscription/prorate/${t}/${i}/${o}`;
    c && (r = r.concat(`?promo_code=${c}`));
    const w = await new p(this.api).get(r, !0, n);
    return w == null ? void 0 : w.data;
  }
  async updateAffiliate(t, i, o, a) {
    const s = await f(t, o, a), c = {
      affiliate: i
    }, n = `/subscription/affiliate/${t}`;
    return await new p(this.api).put(n, c, !0, s);
  }
}
export {
  P as SubscriptionAPI
};
