var w = Object.defineProperty;
var g = (i, t, n) => t in i ? w(i, t, { enumerable: !0, configurable: !0, writable: !0, value: n }) : i[t] = n;
var p = (i, t, n) => (g(i, typeof t != "symbol" ? t + "" : t, n), n);
import { getPermissionAndSubscriptionConfig as a } from "../../../utils/getPermissionAndSubscriptionConfig.js";
import { EditorAPI as u } from "../../EditorAPI.js";
class A {
  constructor(t) {
    p(this, "api");
    this.api = t;
  }
  async connect(t, n, o, r, c) {
    const s = await a(t, r, c), e = `/app/connect_stripe/${t}`, h = new u(this.api), f = {
      refresh_url: n,
      return_url: o
    };
    return await h.post(e, f, !0, s);
  }
  async checkConnection(t, n, o) {
    const r = await a(t, n, o), c = `/app/check_stripe_connection/${t}`;
    return await new u(this.api).get(c, !0, r);
  }
}
export {
  A as StripeAPI
};
