var w = Object.defineProperty;
var g = (i, t, o) => t in i ? w(i, t, { enumerable: !0, configurable: !0, writable: !0, value: o }) : i[t] = o;
var d = (i, t, o) => (g(i, typeof t != "symbol" ? t + "" : t, o), o);
import { EditorAPI as u } from "../EditorAPI.js";
import { getPermissionAndSubscriptionConfig as h } from "../../utils/getPermissionAndSubscriptionConfig.js";
class C {
  constructor(t) {
    d(this, "api");
    this.api = t;
  }
  async getSpaceCoupon(t, o, s) {
    const r = await h(t, o, s), p = `/coupon/${t}`;
    try {
      const a = await new u(this.api).get(p, !0, r);
      return a == null ? void 0 : a.data;
    } catch (c) {
      throw c.response.data;
    }
  }
  async createCoupon(t, o, s, r) {
    const p = await h(o, s, r), c = `/coupon/create/${o}`, a = t;
    try {
      const n = await new u(this.api).post(c, a, !0, p);
      return n == null ? void 0 : n.data;
    } catch (e) {
      throw e.response.data;
    }
  }
  async updateCoupon(t, o, s, r) {
    const p = await h(o, s, r), c = `/coupon/update/${o}/${t.id}`, a = t;
    try {
      const n = await new u(this.api).put(c, a, !0, p);
      return n == null ? void 0 : n.data;
    } catch (e) {
      throw e.response.data;
    }
  }
}
export {
  C as CouponAPI
};
