var u = Object.defineProperty;
var f = (p, t, i) => t in p ? u(p, t, { enumerable: !0, configurable: !0, writable: !0, value: i }) : p[t] = i;
var l = (p, t, i) => (f(p, typeof t != "symbol" ? t + "" : t, i), i);
import { getPermissionAndSubscriptionConfig as c } from "../../utils/getPermissionAndSubscriptionConfig.js";
import { EditorAPI as o } from "../EditorAPI.js";
class k {
  constructor(t) {
    l(this, "api");
    this.api = t;
  }
  async createEmailMessage(t, i, s, a) {
    const e = await c(i, s, a), n = "/emailmarketing/messages";
    return await new o(this.api).post(n, t, !0, e);
  }
  async updateEmailMessage(t, i, s, a, e) {
    const n = await c(s, a, e), r = `/emailmarketing/messages/${i}`;
    return await new o(this.api).put(r, t, !0, n);
  }
  async list(t, i, s) {
    const a = await c(t, i, s), e = new o(this.api), n = "/emailmarketing/campaign";
    return e.get(n, !0, a);
  }
  async listEmailMessages(t, i, s) {
    const a = await c(t, i, s), e = new o(this.api), n = "/emailmarketing/messages";
    return e.get(n, !0, a);
  }
  async getEmailMessage(t, i, s, a) {
    const e = await c(i, s, a), n = new o(this.api), r = `/emailmarketing/messages/${t}`;
    return n.get(r, !0, e);
  }
  async processEmailCampaign(t, i, s, a) {
    const e = await c(i, s, a), n = new o(this.api), r = "/emailmarketing/start";
    return n.post(r, t, !0, e);
  }
  async sendPreviewEmail(t, i, s, a) {
    const e = await c(i, s, a), n = new o(this.api), r = "/emailmarketing/preview/send";
    return n.post(r, t, !0, e);
  }
  async listEmailMetrics(t, i, s, a, e) {
    const n = await c(t, a, e), r = new o(this.api);
    let g = `/emailmarketing/metrics/${i}`;
    const m = [];
    return s.limit ? m.push(`limit=${s.limit}`) : m.push("limit=50"), s.offset ? m.push(`offset=${s.offset}`) : m.push("offset=0"), g = `${g}${m.length > 0 ? `?${m.join("&")}` : ""}`, r.get(g, !0, n);
  }
  async getEmailMetricsSummary(t, i, s, a) {
    const e = await c(t, s, a), n = new o(this.api), r = `/emailmarketing/metrics/${i}/summary`;
    return n.get(r, !0, e);
  }
}
export {
  k as EmailMarketingAPI
};
