var l = Object.defineProperty;
var w = (o, t, e) => t in o ? l(o, t, { enumerable: !0, configurable: !0, writable: !0, value: e }) : o[t] = e;
var u = (o, t, e) => (w(o, typeof t != "symbol" ? t + "" : t, e), e);
import { getSpacePermissionConfig as c } from "../../utils/getSpacePermissionConfig.js";
import { EditorAPI as a } from "../EditorAPI.js";
class y {
  constructor(t) {
    u(this, "api");
    this.api = t;
  }
  async list(t, e, i, n) {
    const s = new a(this.api), p = new URLSearchParams("");
    e && p.append("space_only", e.toString()), Object.keys(n || {}).forEach((r) => {
      p.append(r, String(n == null ? void 0 : n[r]) || "");
    });
    let h = "/template/list";
    h = `${h}?${p.toString()}`;
    const g = await c(t, i);
    return s.get(h, !0, g);
  }
  async listPublic(t) {
    const e = new a(this.api), i = new URLSearchParams();
    Object.keys(t || {}).forEach((s) => {
      !(t != null && t[s]) || i.append(s, String(t == null ? void 0 : t[s]) || "");
    });
    let n = "/template/list/public";
    return n = `${n}?${i.toString()}`, e.get(n, !0);
  }
  async getPublic(t) {
    const e = `/template/public/${t}`;
    return new a(this.api).get(e, !1);
  }
  async get(t, e, i) {
    const n = await c(t, i), s = `/template/${e}`;
    return new a(this.api).get(s, !0, n);
  }
  async use(t, e, i) {
    const n = await c(t, i), s = `/template/use/${e}`;
    return new a(this.api).get(s, !0, n);
  }
  async create(t, e, i) {
    const n = await c(t, i), s = "/template";
    return new a(this.api).post(s, e, !0, n);
  }
  async update(t, e, i, n) {
    const s = await c(t, n), p = `/template/${e}`;
    return new a(this.api).put(p, i, !0, s);
  }
  async listSection() {
    const t = "/template/section";
    return await new a(this.api).get(t, !1);
  }
  async publish(t, e, i) {
    const n = await c(t, i), s = `/template/${e}/publish`;
    return new a(this.api).put(s, null, !0, n);
  }
  async unpublish(t, e, i) {
    const n = await c(t, i), s = `/template/${e}/unpublish`;
    return new a(this.api).put(s, null, !0, n);
  }
  async delete(t, e, i) {
    const n = await c(t, i), s = `/template/${e}`;
    return new a(this.api).delete(s, null, !0, n);
  }
}
export {
  y as TemplateAPI
};
