var d = Object.defineProperty;
var u = (p, t, a) => t in p ? d(p, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : p[t] = a;
var l = (p, t, a) => (u(p, typeof t != "symbol" ? t + "" : t, a), a);
import { getSpacePermissionConfig as r } from "../../utils/getSpacePermissionConfig.js";
import { EditorAPI as h } from "../EditorAPI.js";
class y {
  constructor(t) {
    l(this, "api");
    this.api = t;
  }
  async count(t, a, s) {
    const c = await r(a, s), n = "/contact/count";
    try {
      const o = await new h(this.api).get(n, !0, c);
      return o == null ? void 0 : o.data;
    } catch (i) {
      throw i.response.data;
    }
  }
  async list(t, a, s) {
    const c = await r(a, s);
    let n = "/contact";
    const i = [];
    t.limit ? i.push(`limit=${t.limit}`) : i.push("limit=50"), t.offset ? i.push(`offset=${t.offset}`) : i.push("offset=0"), n = `${n}${i.length > 0 ? `?${i.join("&")}` : ""}`;
    try {
      const e = await new h(this.api).get(n, !0, c);
      return e == null ? void 0 : e.data;
    } catch (o) {
      throw o.response.data;
    }
  }
  async upload(t, a, s) {
    const c = await r(a, s), n = "/contact/subscribe/bulk";
    try {
      const o = await new h(this.api).post(n, JSON.stringify(t), !0, {
        ...c,
        headers: {
          ...c.headers,
          "Content-Type": "application/json"
        }
      });
      return o == null ? void 0 : o.data;
    } catch (i) {
      throw i.response.data;
    }
  }
  async filter(t, a, s) {
    const c = await r(a, s), n = "/contact/filter", o = await new h(this.api).post(n, JSON.stringify(t), !0, {
      ...c,
      headers: {
        ...c.headers,
        "Content-Type": "application/json"
      }
    });
    return o == null ? void 0 : o.data;
  }
  async previewProductEmailImport(t, a, s, c) {
    const n = await r(t, c), i = "/contact/import/product/preview", e = await new h(this.api).post(i, JSON.stringify({
      collection_id: a,
      collection_item_id: s
    }), !0, {
      ...n,
      headers: {
        ...n.headers,
        "Content-Type": "application/json"
      }
    });
    return e == null ? void 0 : e.data;
  }
  async importProductEmail(t, a, s, c) {
    const n = await r(t, c), i = "/contact/import/product", e = await new h(this.api).post(i, JSON.stringify({
      collection_id: a,
      collection_item_id: s
    }), !0, {
      ...n,
      headers: {
        ...n.headers,
        "Content-Type": "application/json"
      }
    });
    return e == null ? void 0 : e.data;
  }
}
export {
  y as ContactAPI
};
