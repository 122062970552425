var u = Object.defineProperty;
var g = (p, t, a) => t in p ? u(p, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : p[t] = a;
var w = (p, t, a) => (g(p, typeof t != "symbol" ? t + "" : t, a), a);
import { getPermissionAndSubscriptionConfig as l } from "../../utils/getPermissionAndSubscriptionConfig.js";
import { getSpacePermissionConfig as d } from "../../utils/getSpacePermissionConfig.js";
import { EditorAPI as r } from "../EditorAPI.js";
class y {
  constructor(t) {
    w(this, "api");
    this.api = t;
  }
  async list(t, a) {
    const n = await d(t, a), c = "/lock", o = await new r(this.api).get(c, !0, n);
    return o == null ? void 0 : o.data;
  }
  async update(t, a, n, c) {
    const s = await d(t, n), o = `/lock/${a}`, i = await new r(this.api).put(o, c, !0, s);
    return i == null ? void 0 : i.data;
  }
  async create(t, a, n, c) {
    const s = await l(t, a, n), o = "/lock", i = await new r(this.api).post(o, c, !0, s);
    return i == null ? void 0 : i.data;
  }
  async delete(t, a, n) {
    const c = await d(t, a), s = `/lock/${n}`, e = await new r(this.api).delete(s, null, !0, c);
    return e == null ? void 0 : e.data;
  }
  async addLock(t, a, n, c) {
    const s = await d(t, a), o = "/lock/page", i = await new r(this.api).post(o, {
      page_id: n,
      lock_id: c
    }, !0, s);
    return i == null ? void 0 : i.data;
  }
  async removeLock(t, a, n, c) {
    const s = await d(t, a), o = "/lock/page", i = await new r(this.api).delete(o, {
      page_id: n,
      lock_id: c
    }, !0, s);
    return i == null ? void 0 : i.data;
  }
}
export {
  y as LockAPI
};
