var h = Object.defineProperty;
var u = (r, t, i) => t in r ? h(r, t, { enumerable: !0, configurable: !0, writable: !0, value: i }) : r[t] = i;
var d = (r, t, i) => (u(r, typeof t != "symbol" ? t + "" : t, i), i);
import { getSpacePermissionConfig as m } from "../../utils/getSpacePermissionConfig.js";
import { getPermissionAndSubscriptionConfig as f } from "../../utils/getPermissionAndSubscriptionConfig.js";
import { EditorAPI as p } from "../EditorAPI.js";
class $ {
  constructor(t) {
    d(this, "api");
    this.api = t;
  }
  async get(t) {
    const i = `/spacepermission/${t}`;
    try {
      const s = await new p(this.api).get(i);
      return s == null ? void 0 : s.data;
    } catch (n) {
      throw n.response.data;
    }
  }
  async transfer(t, i, n) {
    var e;
    const s = `/spacepermission/member/transfer/${t}`, c = await m(t, n), a = await new p(this.api).post(s, i, !0, c);
    return (e = a == null ? void 0 : a.data) == null ? void 0 : e.new_permissions;
  }
  async listMembers(t, i) {
    var a;
    const n = `/spacepermission/member/list/${t}`, s = await m(t, i), o = await new p(this.api).get(n, !0, s);
    return (a = o == null ? void 0 : o.data) == null ? void 0 : a.permissions;
  }
  async addMember(t, i, n, s) {
    var w;
    const c = `/spacepermission/member/add/${t}`, o = await f(t, n, s), e = await new p(this.api).post(c, i, !0, o);
    return (w = e == null ? void 0 : e.data) == null ? void 0 : w.permissions;
  }
  async removeMember(t, i, n) {
    var e;
    const s = `/spacepermission/member/remove/${t}/${i}`, c = await m(t, n), a = await new p(this.api).delete(s, null, !0, c);
    return (e = a == null ? void 0 : a.data) == null ? void 0 : e.permissions;
  }
}
export {
  $ as SpacePermissionAPI
};
