import { ISpace, ISpacePermission } from '@typedream/data';
import { AxiosResponse } from 'axios';
import { EditorAPI } from '../EditorAPI';

export class SpaceAPI {
  api: string;

  constructor(apiBaseURL: string) {
    this.api = apiBaseURL;
  }

  async delete(spaceID: string): Promise<
    AxiosResponse<{
      id: string;
      success: boolean;
      permissions: ISpacePermission[];
    }>
  > {
    const path = `/space/admin/${spaceID}`;

    const api = new EditorAPI(this.api);
    const resp = await api.delete(path, null, true);
    return resp;
  }

  async getPublic(slug: string, type: 'SLUG' | 'CUSTOM_DOMAIN') {
    const path =
      type === 'SLUG' ? `/space/public?slug=${slug}` : `/space/public?custom_domain=${slug}`;
    const api = new EditorAPI(this.api);
    const resp = await api.get(path, false);
    return resp;
  }

  async list() {
    const path = '/space/admin';
    const api = new EditorAPI(this.api);
    const resp = await api.get<Array<ISpace>>(path, true);
    return resp;
  }

  async inviteMember(spaceID: string, email: string, role: 'owner' | 'writer' | 'reader') {
    const path = `/spacepermission/admin/member/add`;
    const payload = {
      email,
      space_id: spaceID,
      role,
    };

    const api = new EditorAPI(this.api);
    const resp = await api.post(path, payload, true);
    return resp;
  }

  async transferSubscription(fromSpaceID: string, toSpaceID: string) {
    const path = `/subscription/admin/transfer`;
    const payload = {
      from_space_id: fromSpaceID,
      to_space_id: toSpaceID,
    };

    const api = new EditorAPI(this.api);
    const resp = await api.post(path, payload, true);
    return resp;
  }

  async get(spaceID: string) {
    const path = `/space/admin/${spaceID}`;
    const api = new EditorAPI(this.api);
    const resp = await api.get<ISpace>(path, true);
    return resp;
  }
}
