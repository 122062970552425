const t = {
  mainImage: {
    key: "mainImage",
    orientation: "landscape",
    width: 980,
    height: 575
  }
}, i = {
  image: {
    key: "image",
    orientation: "landscape",
    width: 980,
    height: 436
  }
}, a = {
  image: {
    key: "image",
    orientation: "landscape",
    width: 1104,
    height: 492
  }
}, e = {
  type: "object",
  properties: {
    title: {
      type: "string",
      description: "A string that is the tagline to be used in this hero section, must be at least 4 words and at most 6 words"
    },
    subtitle: {
      type: "string",
      description: "A string that is the subtitle to be used in this hero section, must be at least 15 words and at most 20 words"
    },
    image: {
      type: "string",
      description: "A string that describes the image to be used in this hero section in great detail."
    },
    buttonText: {
      type: "string",
      description: "A string that is the text to be used in the button in this hero section"
    },
    backgroundPattern: {
      type: "string",
      description: 'A string that is the background pattern to be used in this hero section. It can be "{{dotGrid}}", "{{dotSquare}}", or "{{lineGrid}}"',
      enum: ["{{dotGrid}}", "{{dotSquare}}", "{{lineGrid}}"]
    }
  },
  required: ["title", "subtitle", "image", "buttonText", "backgroundPattern"]
}, s = {
  hero_1: {
    description: "Hero section where everything is centered. On the top there is a big title, and below it is a smaller lighter subtitle. Below that is a button for the main call to action. Below the button, there is a large landscape image. This hero section is suitable for most websites, especially for SaaS products. It is not suitable for personal websites showcasing the person's profile picture. The title is bold and large, the subtitle is smaller and lighter. On top of the background, there's also subtle pattern like dots or lines.",
    requiredData: e,
    images: t
  },
  hero_2: {
    description: "A hero section with 2 columns. On the left there is a big title, a smaller lighter subtitle, and a button for the main call to action. On the right, there is a large landscape image. This hero section is suitable for most websites, especially for SaaS products. It is also great for personal websites showcasing the person's profile picture. The title is bold and large, the subtitle is smaller and lighter. On top of the background, there's also subtle pattern like dots or lines.",
    requiredData: e,
    images: t
  },
  hero_3: {
    description: "A hero section with 2 columns. On the left, there is a large landscape image. On the right, there is a big title, a smaller lighter subtitle, and a button for the main call to action. This hero section is suitable for most websites, especially for SaaS products. It is also great for personal websites showcasing the person's profile picture. The title is bold and large, the subtitle is smaller and lighter. On top of the background, there's also subtle pattern like dots or lines.",
    requiredData: e,
    images: t
  },
  hero_4: {
    description: "A hero section where everything is left aligned with a big image at the bottom. On the top there is a big title, and below it is a smaller lighter subtitle, both are left aligned. Below that is a button for the main call to action. Below the button, there is a large landscape image. This hero section is suitable for most websites that displays a large photo. It is not suitable for personal websites showcasing the person's profile picture. The title is bold and large, the subtitle is smaller and lighter. On top of the background, there's also subtle pattern like dots or lines.",
    requiredData: e,
    images: i
  },
  hero_5: {
    description: "A hero section with titles on top and a large landscape image at the bottom. On the titles part on top, there are 2 columns. On the left, there is a large title, on the right there is a lighter subtitle and a button. This hero section is suitable for most websites that displays a large photo. It is not suitable for personal websites showcasing the person's profile picture. The title is bold and large, the subtitle is smaller and lighter. On top of the background, there's also subtle pattern like dots or lines.",
    requiredData: e,
    images: a
  },
  hero_6: {
    description: "A hero section with a large landscape image on top and some titles at the bottom. On the titles part at the bottom, there are 2 columns. On the left, there is a large title, on the right there is a lighter subtitle and a button. This hero section is suitable for most websites that displays a large photo. It is not suitable for personal websites showcasing the person's profile picture. The title is bold and large, the subtitle is smaller and lighter. On top of the background, there's also subtle pattern like dots or lines.",
    requiredData: e,
    images: a
  },
  hero_7: {
    description: "A hero section with a large landscape image on top and some titles at the bottom. On the titles part at the bottom, there are 2 columns. On the left, there is a large title, on the right there is a lighter subtitle and a button. This hero section is suitable for most websites that displays a large photo. It is not suitable for personal websites showcasing the person's profile picture. The title is bold and large, the subtitle is smaller and lighter. On top of the background, there's also subtle pattern like dots or lines.",
    requiredData: e,
    images: {
      image: {
        key: "image",
        orientation: "landscape",
        width: 1900,
        height: 451
      }
    }
  },
  hero_8: {
    description: "A hero section with a large landscape image on top and some titles at the bottom. On the titles part at the bottom, there is a large title, and below the title there is a smaller subtitle and a button. This hero section is suitable for most websites that displays a large photo. It is not suitable for personal websites showcasing the person's profile picture. The title is bold and large, the subtitle is smaller and lighter. On top of the background, there's also subtle pattern like dots or lines.",
    requiredData: e,
    images: i
  },
  hero_9: {
    description: "A hero section with a large title, and below the title there is a smaller subtitle and a button. This hero section is only suitable for websites that does not need to display any image. The title is bold and large, the subtitle is smaller and lighter. On top of the background, there's also subtle pattern like dots or lines.",
    requiredData: e
  },
  hero_10: {
    description: "A hero section with a large title, and below the title there is a smaller subtitle and a button. Below the button, there is a collection of 5 images of software mockups arranged horizontally. This hero section is only suitable for SaaS / software websites. The title is bold and large, the subtitle is smaller and lighter. On top of the background, there's also subtle pattern like dots or lines.",
    requiredData: e
  },
  hero_11: {
    description: "A hero section with a large landscape image as a background. In the middle, there is a large title, and below the title there is a smaller subtitle and a button. This hero section is suitable for most websites that displays a large photo as a background. It is not suitable for Saas/software websites aiming to showcase a mockup of their platform. The title is bold and large, the subtitle is smaller and lighter. On top of the background, there's also subtle pattern like dots or lines.",
    requiredData: e,
    images: {
      image: {
        key: "image",
        orientation: "landscape",
        width: 2e3,
        height: 640
      }
    }
  },
  hero_12: {
    description: "A hero section with a large landscape image as a background. Inside this section, there is a large title aligned, and below the title there is a smaller subtitle and a button, all aligned left. This hero section is suitable for most websites that displays a large photo as a background. It is not suitable for Saas/software websites aiming to showcase a mockup of their platform. The title is bold and large, the subtitle is smaller and lighter. On top of the background, there's also subtle pattern like dots or lines.",
    requiredData: e,
    images: {
      image: {
        key: "image",
        orientation: "landscape",
        width: 2e3,
        height: 640
      }
    }
  },
  hero_13: {
    description: "A hero section with a large card-like section in the middle with a landscape image as a background. Inside this section, there is a large title aligned, and below the title there is a smaller subtitle and a button, all aligned left. This hero section is suitable for most websites that displays a large photo as a background. It is not suitable for Saas/software websites aiming to showcase a mockup of their platform. The title is bold and large, the subtitle is smaller and lighter. On top of the background, there's also subtle pattern like dots or lines.",
    requiredData: e,
    images: {
      image: {
        key: "image",
        orientation: "landscape",
        width: 1100,
        height: 585
      }
    }
  },
  hero_14: {
    description: "A hero section with a large card-like section in the middle with a landscape image as a background. Inside this section, there is a large title aligned, and below the title there is a smaller subtitle and a button, all aligned center. This hero section is suitable for most websites that displays a large photo as a background. It is not suitable for Saas/software websites aiming to showcase a mockup of their platform. The title is bold and large, the subtitle is smaller and lighter. On top of the background, there's also subtle pattern like dots or lines.",
    requiredData: e,
    images: {
      image: {
        key: "image",
        orientation: "landscape",
        width: 1100,
        height: 585
      }
    }
  }
};
export {
  s as HeroTemplates
};
