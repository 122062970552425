var l = Object.defineProperty;
var g = (n, t, i) => t in n ? l(n, t, { enumerable: !0, configurable: !0, writable: !0, value: i }) : n[t] = i;
var c = (n, t, i) => (g(n, typeof t != "symbol" ? t + "" : t, i), i);
import { EditorAPI as u } from "../EditorAPI.js";
import { getPermissionAndSubscriptionConfig as p } from "../../utils/getPermissionAndSubscriptionConfig.js";
class $ {
  constructor(t) {
    c(this, "api");
    this.api = t;
  }
  backup(t, i, s) {
    const e = new u(this.api);
    function o(r) {
      return new Promise((a, h) => {
        r || h(null), e.post(`/history/backup/${t}/${i}`, s).then((f) => a(f)).catch(() => {
          setTimeout(() => o(r - 1), 3e3);
        });
      });
    }
    return o(3);
  }
  async getVersionList(t, i, s, e) {
    if (!i || !t)
      return null;
    const o = await p(i, s, e), r = `/history/list/${i}/${t}`;
    return new u(this.api).get(r, !0, o);
  }
  async getVersionContent(t, i, s, e) {
    if (!i)
      return null;
    const o = await p(i, s, e), r = `/history/${t}`;
    return new u(this.api).get(r, !0, o);
  }
}
export {
  $ as HistoryAPI
};
