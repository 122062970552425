var h = Object.defineProperty;
var P = (p, t, a) => t in p ? h(p, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : p[t] = a;
var u = (p, t, a) => (P(p, typeof t != "symbol" ? t + "" : t, a), a);
import { getSpacePermissionConfig as r } from "../../utils/getSpacePermissionConfig.js";
import { EditorAPI as c } from "../EditorAPI.js";
import { getPermissionAndSubscriptionConfig as w } from "../../utils/getPermissionAndSubscriptionConfig.js";
class l {
  constructor(t) {
    u(this, "api");
    this.api = t;
  }
  async listProjects(t, a) {
    const e = await r(t, a), i = `/ai_project/list/${t}`;
    return await new c(this.api).get(i, !0, e);
  }
  async listProjectsByUserID() {
    const t = "/ai_project/list";
    return await new c(this.api).get(t, !0);
  }
  async createProject(t, a, e) {
    const i = {
      ...t,
      name: t.name || "Untitled"
    }, s = await w(t.space_id, a, e), n = "/ai_project/create";
    return await new c(this.api).post(n, i, !0, s);
  }
  async getProject(t, a, e) {
    const i = await r(a, e), s = `/ai_project/${t}`;
    return await new c(this.api).get(s, !0, i);
  }
  async updateProject(t, a, e) {
    const i = await w(t.space_id, a, e), s = `/ai_project/${t.id}`;
    return await new c(this.api).patch(s, t, !0, i);
  }
  async listPages(t, a, e) {
    const i = await r(a, e), s = `/ai_page/list/${t}`;
    return await new c(this.api).get(s, !0, i);
  }
  async createPage(t, a, e, i) {
    const s = {
      ...t,
      name: t.name || "Untitled"
    }, n = await w(a, e, i), o = "/ai_page/create";
    return await new c(this.api).post(o, s, !0, n);
  }
  async updatePage(t, a, e, i) {
    const s = await w(a, e, i), n = `/ai_page/${t.id}`;
    return await new c(this.api).patch(n, t, !0, s);
  }
  async getPage(t, a, e) {
    const i = await r(a, e), s = `/ai_page/${t}`;
    return await new c(this.api).get(s, !0, i);
  }
}
export {
  l as AIProjectAPI
};
