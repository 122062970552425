var l = Object.defineProperty;
var w = (o, t, n) => t in o ? l(o, t, { enumerable: !0, configurable: !0, writable: !0, value: n }) : o[t] = n;
var s = (o, t, n) => (w(o, typeof t != "symbol" ? t + "" : t, n), n);
import { getPermissionAndSubscriptionConfig as e } from "../../../utils/getPermissionAndSubscriptionConfig.js";
import { EditorAPI as u } from "../../EditorAPI.js";
class P {
  constructor(t) {
    s(this, "api");
    this.api = t;
  }
  async connect(t, n, a, c, i) {
    const r = await e(t, c, i), p = `/app/connect_paypal/${t}`, h = new u(this.api), f = {
      refresh_url: n,
      return_url: a
    };
    return await h.post(p, f, !0, r);
  }
  async checkConnection(t, n, a) {
    const c = await e(t, n, a), i = `/app/check_paypal_connection/${t}`;
    return await new u(this.api).get(i, !0, c);
  }
}
export {
  P as PaypalAPI
};
