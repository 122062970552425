var _ = Object.defineProperty;
var C = (h, t, n) => t in h ? _(h, t, { enumerable: !0, configurable: !0, writable: !0, value: n }) : h[t] = n;
var w = (h, t, n) => (C(h, typeof t != "symbol" ? t + "" : t, n), n);
import { EditorAPI as u } from "../EditorAPI.js";
import { getPermissionAndSubscriptionConfig as l } from "../../utils/getPermissionAndSubscriptionConfig.js";
import { OAUTH_REDIRECT_URI as g } from "../../constants/constants.js";
class z {
  constructor(t) {
    w(this, "api");
    this.api = t;
  }
  async create(t, n, e, a, i) {
    const o = await l(t, a, i), c = {
      name: n,
      space_id: t,
      token: e
    }, s = "/app/create", r = new u(this.api);
    return r.logoutOnUnauthorized = !1, await r.post(s, c, !0, o);
  }
  async connectOAuth(t, n, e, a, i, o) {
    const c = await l(t, i, o), s = {
      app: {
        name: n,
        space_id: t
      },
      req: {
        code: e,
        grant_type: "authorization_code",
        redirect_uri: `${a}${g}`
      }
    }, r = "/app/auth/callback/create", p = new u(this.api);
    return p.logoutOnUnauthorized = !1, await p.post(r, s, !0, c);
  }
  async updateOAuth(t, n, e, a, i, o, c) {
    const s = await l(t, o, c), r = {
      app: {
        id: n,
        name: e,
        space_id: t
      },
      req: {
        code: a,
        grant_type: "authorization_code",
        redirect_uri: `${i}${g}`
      }
    }, p = "/app/auth/callback/update", d = new u(this.api);
    return d.logoutOnUnauthorized = !1, await d.post(p, r, !0, s);
  }
  async update(t, n, e, a, i, o) {
    const c = await l(t, i, o), s = {
      id: n,
      name: e,
      space_id: t,
      token: a
    }, r = "/app/update", p = new u(this.api);
    return p.logoutOnUnauthorized = !1, await p.put(r, s, !0, c);
  }
  async delete(t, n, e, a, i) {
    const o = await l(t, a, i), c = {
      id: n,
      name: e,
      space_id: t
    }, s = "/app/delete", r = new u(this.api);
    return r.logoutOnUnauthorized = !1, await r.delete(s, c, !0, o);
  }
  async list(t, n, e) {
    const a = await l(t, n, e), i = `/app/list/${t}`, o = new u(this.api);
    return o.logoutOnUnauthorized = !1, await o.get(i, !0, a);
  }
  async connectCollection(t, n, e, a) {
    const i = await l(t, e, a), o = `/app/connect_collection/${t}`, c = new u(this.api);
    c.logoutOnUnauthorized = !1;
    const s = await c.post(o, n, !0, i);
    return s == null ? void 0 : s.data;
  }
  async updateCollectionContent(t, n, e, a, i) {
    const o = new u(this.api);
    o.logoutOnUnauthorized = !1;
    const c = await l(t, a, i), s = `/app/collection_content/${t}/${n}`;
    return await o.put(s, e, !0, c);
  }
  getCollectionContentURLPrefix() {
    return `${this.api}/app/collection_content/`;
  }
  getCollectionContentURL(t) {
    return `${this.getCollectionContentURLPrefix()}${t}`;
  }
  async publishCollectionContent(t) {
    const { spaceID: n, dataID: e, draftContentURL: a, space: i, spacePermissionTokenHandler: o, subscriptionTokenHandler: c } = t, s = this.getCollectionContentURLPrefix();
    if (!a.includes(s))
      throw new Error("Draft content URL is not from Typedream's collection_content url");
    const r = a.replace(s, ""), p = new u(this.api);
    p.logoutOnUnauthorized = !1;
    const d = await l(n, o, c), f = `/app/publish_collection_content/${n}/${e}/${r}`;
    return await p.put(f, i, !0, d);
  }
  async getAuthorizationCode(t, n, e, a) {
    const i = await l(n, e, a), o = "/app/oauth/code";
    return await new u(this.api).post(o, t, !0, i);
  }
  async checkOpenAIConnection(t, n, e) {
    const a = await l(t, n, e), i = `/app/check_openai_connection/${t}`;
    return await new u(this.api).get(i, !0, a);
  }
}
export {
  z as AppAPI
};
