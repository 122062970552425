import { logout as s } from "./jwt/logout.js";
import { SPACE_PERMISSION_HEADER_KEY as a, SUBSCRIPTION_HEADER_KEY as c } from "../constants/constants.js";
async function u(o, t, e, n) {
  const i = await t.getSpacePermission(o), r = await e.getSubscription(o);
  if (!r || !i)
    throw s(n), new Error("You are not authorized to view this page.");
  return {
    headers: {
      [a]: `Bearer ${i.token}`,
      [c]: `Bearer ${r.token}`
    }
  };
}
export {
  u as getPermissionAndSubscriptionConfig
};
