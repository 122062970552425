/* eslint-disable react/jsx-props-no-spreading */

import '../styles/globals.css';
import '../styles/styles.scss';
// import '@typedream/dream-editor/dist/style.css';
import 'react-resizable/css/styles.css';
import '@typedream/ui/ui-globals.css';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import { API as DataAPI } from '@typedream/data';
import { API } from '../api';
import Navbar from '../components/Navbar';
import { EDITOR_API_BASE_URL } from '../constants';

API.init(EDITOR_API_BASE_URL);
DataAPI.init(EDITOR_API_BASE_URL);

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();
  const isNavbarShown = ![
    '/preview',
    '/templates/new-x',
    '/templates/edit/[space_id]/[template_id]',
  ].includes(router.pathname);

  return (
    <>
      <ToastContainer />
      {isNavbarShown && <Navbar />}
      <Component {...pageProps} />
    </>
  );
}
export default MyApp;
