import { IPage, ISpace } from '@typedream/data';
import { EditorAPI } from '../EditorAPI';

export interface SsrProps {
  space: ISpace;
  page: IPage;
  is_child_template: boolean;
  child_template_slug: string;
}

export class PageAPI {
  api: string;

  constructor(apiBaseURL: string) {
    this.api = apiBaseURL;
  }

  async getPublishedSpacePage({
    type,
    slugOrDomain,
    path,
  }: {
    type: 'SLUG' | 'CUSTOM_DOMAIN';
    slugOrDomain: string;
    path: string | string[];
  }) {
    let paths = path;
    if (typeof paths === 'string') paths = [path as string];
    const api = new EditorAPI(this.api);
    const apiPath = '/page/get_published_space_page';
    const data = {
      [type === 'SLUG' ? 'slug' : 'custom_domain']: slugOrDomain,
      paths,
    };
    const resp = await api.post<SsrProps>(apiPath, data);
    return resp;
  }
}

export default PageAPI;
