import AccessTokenHandler from './accessTokenHandler';

async function logout(
  logoutCallback?: () => Promise<void>,
) {
  if (logoutCallback) {
    await logoutCallback();
  }
  AccessTokenHandler.removeTokens();
  window.location.href = '/';
}

export default logout;
