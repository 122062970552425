import { logout as t } from "./jwt/logout.js";
import { SPACE_PERMISSION_HEADER_KEY as n } from "../constants/constants.js";
async function f(e, r, i) {
  const o = await r.getSpacePermission(e);
  if (!o)
    throw t(i), new Error("You are not authorized to view this page.");
  return {
    headers: {
      [n]: `Bearer ${o.token}`
    }
  };
}
export {
  f as getSpacePermissionConfig
};
