var h = Object.defineProperty;
var g = (u, t, a) => t in u ? h(u, t, { enumerable: !0, configurable: !0, writable: !0, value: a }) : u[t] = a;
var d = (u, t, a) => (g(u, typeof t != "symbol" ? t + "" : t, a), a);
import { getPermissionAndSubscriptionConfig as e } from "../../utils/getPermissionAndSubscriptionConfig.js";
import { EditorAPI as p } from "../EditorAPI.js";
class y {
  constructor(t) {
    d(this, "api");
    this.api = t;
  }
  async create(t, a, n, i) {
    const o = await e(a, n, i), s = `/product/create/${a}`;
    return await new p(this.api).post(s, t, !0, o);
  }
  async query(t, a, n, i) {
    const o = await e(t, n, i), s = `/product/list/${t}?limit=${a.limit}&offset=${a.offset}&order=desc`;
    return await new p(this.api).get(s, !0, o);
  }
  async get(t, a, n, i) {
    const o = await e(a, n, i), s = `/product/${a}/${t}`;
    return await new p(this.api).get(s, !0, o);
  }
  async update(t, a, n, i, o) {
    const s = await e(a, i, o), c = `/product/${a}/${t}`;
    return await new p(this.api).put(c, n, !0, s);
  }
  async publish(t, a, n, i, o) {
    const s = await e(a, i, o), c = `/product/publish/${a}/${t}`, r = new p(this.api), w = { public: n };
    return await r.put(c, w, !0, s);
  }
  async getProductCollection(t, a, n) {
    const i = await e(t, a, n), o = `/product/collection/${t}`;
    return await new p(this.api).get(o, !0, i);
  }
  async updateContactEmail(t, a, n, i) {
    const o = await e(t, n, i), s = `/product/update/contact_email/${t}`, c = new p(this.api), r = { contact_email: a };
    return await c.put(s, r, !0, o);
  }
  async updateSenderName(t, a, n, i) {
    const o = await e(t, n, i), s = `/product/update/mailing/${t}`, c = new p(this.api), r = { sender_name: a };
    return await c.put(s, r, !0, o);
  }
  async countProducts(t, a, n) {
    const i = await e(t, a, n), o = `/product/count/${t}`;
    return await new p(this.api).get(o, !0, i);
  }
  async delete(t, a, n, i) {
    const o = await e(a, n, i), s = `/product/${a}/${t}`;
    return await new p(this.api).delete(s, null, !0, o);
  }
}
export {
  y as ProductsAPI
};
