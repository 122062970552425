import { EditorAPI } from '../EditorAPI';

export class DocumentAPI {
  api: string;

  constructor(apiBaseURL: string) {
    this.api = apiBaseURL;
  }

  upload(file: File, spaceID: string) {
    const body = new FormData();
    body.append('file', file);
    body.append('file_name', file.name);
    const api = new EditorAPI(this.api);
    const url = `/document/admin/${spaceID}`;
    return api.post(url, body, true);
  }

  getURL(filename: string) {
    return `${this.api}/document/public/${filename}`;
  }
}
