import { AccessToken as r } from "./AccessToken.js";
const o = "act";
class l {
  static storeTokens(t) {
    var e;
    (e = window == null ? void 0 : window.localStorage) == null || e.setItem(o, t);
  }
  static getAccessToken() {
    var t;
    if (typeof window < "u") {
      const e = (t = window == null ? void 0 : window.localStorage) == null ? void 0 : t.getItem(o);
      if (!e || e === "undefined" || e === "null")
        return null;
      const n = new r(e);
      return (n == null ? void 0 : n.getExpiration()) < new Date().getTime() / 1e3 ? null : n;
    }
    return null;
  }
  static removeTokens() {
    var t;
    try {
      (t = window == null ? void 0 : window.localStorage) == null || t.removeItem(o);
    } catch {
    }
  }
}
export {
  l as AccessTokenHandler
};
