const e = {
  description: "Frequently asked questions",
  type: "object",
  properties: {
    title: {
      description: "The title of the FAQ section",
      type: "string"
    },
    subtitle: {
      description: "The subtitle of the FAQ section",
      type: "string"
    },
    questions: {
      description: "The list of frequently asked questions and their answers",
      type: "object",
      properties: {
        0: {
          description: "The first question and answer",
          type: "object",
          properties: {
            question: {
              description: "The question",
              type: "string"
            },
            answer: {
              description: "The answer",
              type: "string"
            }
          },
          required: ["question", "answer"]
        },
        1: {
          description: "The second question and answer",
          type: "object",
          properties: {
            question: {
              description: "The question",
              type: "string"
            },
            answer: {
              description: "The answer",
              type: "string"
            }
          },
          required: ["question", "answer"]
        },
        2: {
          description: "The third question and answer",
          type: "object",
          properties: {
            question: {
              description: "The question",
              type: "string"
            },
            answer: {
              description: "The answer",
              type: "string"
            }
          },
          required: ["question", "answer"]
        },
        3: {
          description: "The fourth question and answer",
          type: "object",
          properties: {
            question: {
              description: "The question",
              type: "string"
            },
            answer: {
              description: "The answer",
              type: "string"
            }
          },
          required: ["question", "answer"]
        },
        4: {
          description: "The fifth question and answer",
          type: "object",
          properties: {
            question: {
              description: "The question",
              type: "string"
            },
            answer: {
              description: "The answer",
              type: "string"
            }
          },
          required: ["question", "answer"]
        }
      },
      required: ["0", "1", "2", "3", "4"]
    }
  },
  required: ["title", "subtitle", "questions"]
}, t = {
  faq_1: {
    description: "A list of questions & answers displayed in accordion style where each question can be expanded to reveal the answer. The accordion style is specifically plain where there is a plus icon on the left of each question, but no separator between the questions.",
    requiredData: e
  },
  faq_2: {
    description: "A list of questions & answers displayed in accordion style where each question can be expanded to reveal the answer. The accordion style is with cards where each question is enclosed in a card container and there is a plus icon on the right of each question",
    requiredData: e
  },
  faq_3: {
    description: "A list of questions & answers displayed in accordion style where each question can be expanded to reveal the answer. The accordion style is with buttons where each question is enclosed in a rounded-cornered container, when collapsed the container looks like a button with fully-rounded corners. There is a chevron icon on the right of each question",
    requiredData: e
  }
};
export {
  t as FAQTemplates
};
