import { useRouter } from 'next/router';
import React from 'react';
import logout from '../../utils/jwt/logout';

function Navbar() {
  const router = useRouter();

  const [isBackButtonShown, setIsbackButtonShown] = React.useState(false);

  React.useEffect(() => {
    function handleWindowChange() {
      setIsbackButtonShown(window.history.length > 2 && window.history.state.idx !== 0);
    }

    window.addEventListener('popstate', handleWindowChange);
    return () => {
      window.removeEventListener('popstate', handleWindowChange);
    };
  }, []);

  return (
    <nav className="navbar" role="navigation" aria-label="main navigation">
      <div className="navbar-brand">
        <a className="navbar-item" href="/">
          <img alt="logo" src="https://build.typedream.com/images/logo@100.png" />
        </a>

        {isBackButtonShown && (
        <div className="navbar-item">
          <div
            className="button is-light"
            role="none"
            onClick={router.back}
          >
            Back
          </div>
        </div>
        )}
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a role="button" className="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navbarBasicExample">
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>

      <div className="navbar-end">
        <div className="navbar-item">
          <div role="none" className="button is-light" onClick={() => logout()}>
            Log Out
          </div>
        </div>
      </div>
    </nav>
  );
}
export default Navbar;
