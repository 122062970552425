const e = {
  type: "object",
  properties: {
    title: {
      type: "string",
      description: "A string that is the title of this features section, must be at least 5 words and at most 8 words"
    },
    actions: {
      type: "object",
      properties: {
        0: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the call to action, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the call to action, must be at least 8 words and at most 10 words"
            },
            actionText: {
              type: "string",
              description: "A short string of the call to action, must be at least 1 word and at most 3 words"
            }
          },
          required: ["title", "subtitle", "actionText"]
        },
        1: {
          type: "object",
          properties: {
            title: {
              type: "string",
              description: "A short string that is the title of the call to action, must be at least 1 word and at most 3 words"
            },
            subtitle: {
              type: "string",
              description: "A longer string description of the call to action, must be at least 8 words and at most 10 words"
            },
            actionText: {
              type: "string",
              description: "A short string of the call to action, must be at least 1 word and at most 3 words"
            }
          },
          required: ["title", "subtitle", "actionText"]
        }
      },
      required: ["0", "1"]
    }
  },
  required: ["title", "actions"]
}, t = {
  type: "object",
  properties: {
    title: {
      type: "string",
      description: "A string that is the title of this features section, must be at least 5 words and at most 8 words"
    },
    actionText: {
      type: "string",
      description: "A short string of the call to action, must be at least 1 word and at most 3 words"
    }
  },
  required: ["title", "actionText"]
}, i = {
  type: "object",
  properties: {
    title: {
      type: "string",
      description: "A string that is the title of this features section, must be at least 5 words and at most 8 words"
    },
    subtitle: {
      type: "string",
      description: "A longer string description of the call to action, must be at least 8 words and at most 10 words"
    },
    actionText: {
      type: "string",
      description: "A short string of the call to action, must be at least 1 word and at most 3 words"
    }
  },
  required: ["title", "subtitle", "actionText"]
}, a = {
  callToAction_1: {
    description: "The layout of this call to action section is streamlined and divided into three distinct parts, positioned on a horizontal axis. The leftmost part features a bold, large-sized header that anchors the section. Moving rightward, the central and rightmost parts consist of two separate yet visually similar blocks. Each block contains an icon at the top, followed by a title and a brief descriptive segment beneath the icon, culminating with a call-to-action link at the bottom. The icons are stylized and appear to be thematic, potentially representing the services or steps described in the section. The design is clean and spacious, with plenty of white space around each element to prevent visual clutter, facilitating a clear and focused user journey from the section header to the actionable links.",
    requiredData: e
  },
  callToAction_2: {
    description: "The layout of this call to action section is a centered, single-column design on a plain background. Dominating the section is a large, bold header that likely serves as the main call to action or statement. Directly below the header, a graphic element adds a visual accent, which is then followed by a prominent button designed to stand out, possibly inviting user interaction. The simplicity of the design, with generous use of whitespace, directs attention to the central message and the interaction point without any additional distractions. This minimalist approach suggests a focus on clarity and directness in the user's journey on the page.",
    requiredData: t
  },
  callToAction_3: {
    description: "This call to action section presents a centered layout on a light background. The focal point is a large header at the top, likely the main message or call to action. Below the header, there is a single icon, centrally aligned, providing a visual element related to the section's theme. Beneath the icon, there is a button, possibly for initiating a specific action or navigating further within the website. The design is minimalist, using space and positioning to draw attention to the central elements, creating a clean and uncluttered user experience.",
    requiredData: t
  },
  callToAction_4: {
    description: "The call to action section features a two-part layout. On the left side, there's a card-like element with rounded corners that likely contains some text and a prominent button, which seems to be the main interactive element inviting user engagement. To the right, the larger area is divided into an upper section and a lower section. The upper section appears to have some form of heading, and below it, a graphical representation, possibly a chart or graph, spreads across this part, suggesting a display of data or progress over time. The lower section shows a list with icons, which might be indicative of user accounts or other listable items. Overall, the layout is clean, utilizing whitespace effectively to create a division between the call to action on the left and the informational or interactive content on the right.",
    requiredData: i
  }
};
export {
  a as CallToActionTemplates
};
